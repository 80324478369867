<template>
    <div id="wrapper">

      <Header title="Dashboard" />

      <Sidebar />

      <div class="content-page">
        <div class="content">
          <div class="container-fluid">

            <div class="row">

                <div class="col-xl-3 col-md-6">
                    <router-link to="/dashboard/orders">
                      <div class="card">
                          <div class="card-body">
                              <h4 class="header-title mt-0 mb-4">Total Orders</h4>

                              <div class="widget-chart-1">
                                  <div class="widget-chart-box-1 float-start" dir="ltr">
                                    <i class="mdi mdi-cart text-success" style="font-size: xxx-large;"></i>
                                  </div>

                                  <div class="widget-detail-1 text-end">
                                      <h2 class="fw-normal pt-2 mb-1"> 256 </h2>
                                      <p class="text-muted mb-1">Orders today</p>
                                  </div>
                              </div>
                          </div>
                      </div>
                    </router-link>
                </div><!-- end col -->

                <div class="col-xl-3 col-md-6">
                    <router-link to="/dashboard/rents">
                      <div class="card">
                          <div class="card-body">
                              <h4 class="header-title mt-0 mb-4">Total Rents</h4>

                              <div class="widget-chart-1">
                                  <div class="widget-chart-box-1 float-start" dir="ltr">
                                    <i class="mdi mdi-bike text-primary" style="font-size: xxx-large;"></i>
                                  </div>

                                  <div class="widget-detail-1 text-end">
                                      <h2 class="fw-normal pt-2 mb-1"> 256 </h2>
                                      <p class="text-muted mb-1">Rents today</p>
                                  </div>
                              </div>
                          </div>
                      </div>
                    </router-link>
                </div><!-- end col -->

                <div class="col-xl-3 col-md-6">
                    <router-link to="/dashboard/products">
                      <div class="card">
                          <div class="card-body">
                              <h4 class="header-title mt-0 mb-4">Total Items</h4>

                              <div class="widget-chart-1">
                                  <div class="widget-chart-box-1 float-start" dir="ltr">
                                    <i class="mdi mdi-clipboard-list-outline text-info" style="font-size: xxx-large;"></i>
                                  </div>

                                  <div class="widget-detail-1 text-end">
                                      <h2 class="fw-normal pt-2 mb-1"> 23 </h2>
                                      <p class="text-muted mb-1">All Items</p>
                                  </div>
                              </div>
                          </div>
                      </div>
                    </router-link>
                </div><!-- end col -->

                <div class="col-xl-3 col-md-6">
                    <router-link to="/dashboard/road-trails">
                      <div class="card">
                          <div class="card-body">
                              <h4 class="header-title mt-0 mb-4">Total Road Trails</h4>

                              <div class="widget-chart-1">
                                  <div class="widget-chart-box-1 float-start" dir="ltr">
                                    <i class="mdi mdi-google-maps text-danger" style="font-size: xxx-large;"></i>
                                  </div>

                                  <div class="widget-detail-1 text-end">
                                      <h2 class="fw-normal pt-2 mb-1"> 29 </h2>
                                      <p class="text-muted mb-1">All Road Trails</p>
                                  </div>
                              </div>
                          </div>
                      </div>
                    </router-link>
                </div><!-- end col -->

            </div>
            <!-- end row -->
          </div>
        </div>
      </div>
      
    </div>
</template>

<script>
  import Header from '../../components/Header'; 
  import Sidebar from '../../components/Sidebar'; 
  export default {
    name: 'DashboardPage',
    components: {
      Header,
      Sidebar
    },
    data() {
      return {
      };
    },
    methods: {
    },
  };
</script>

<template>
  <div class="alert" :class="['alert', alertClass, { show: visible }]">
      <div class="text-center">
          <i class="h1 text-white" :class="icon()"></i>
          <h4 class="mt-2 text-white">{{title()}}</h4>
          <p class="mt-3 text-white">{{ message }}</p>
          <button type="button" class="btn btn-light my-2" @click="dismiss">Close</button>
      </div>
  </div>
</template>

<script>
  export default {
    name: 'AlertComponent',
    props: {
      type: {
        type: String,
        required: true,
        validator: (value) => ['success', 'warning', 'danger', 'info'].includes(value)
      },
      message: {
        type: String,
        required: true
      },
      visible: {
        type: Boolean,
        required: true
      }
    },
    computed: {
      alertClass() {
        return `bg-${this.type}`;
      }
    },
    methods: {
      dismiss() {
        this.$emit('dismiss');
      },
      title() {
        switch (this.type) {
          case 'success':
            return 'Success';
          case 'warning':
            return 'Warning';
          case 'danger':
            return 'Error';
          default:
            return '';
        }
      },
      icon() {
        switch (this.type) {
          case 'success':
            return 'dripicons-checkmark';
          case 'warning':
            return 'dripicons-warning';
          case 'danger':
            return 'dripicons-wrong';
          default:
            return '';
        }
      },
      bg() {
        switch (this.type) {
          case 'success':
            return 'bg-success';
          case 'warning':
            return 'bg-warning';
          case 'danger':
            return 'bg-danger';
          default:
            return '';
        }
      }
    }
  }
</script>

<style scoped>
  .alert {
    position: absolute;
    top: -300px;
    right: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10000;
    opacity: 0;
    transition: all 0.5s ease-in-out;
    width: fit-content;
  }

  .alert.show {
    top: 10px;
    opacity: 1;
  }

  .alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }

  .alert-warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
  }

  .alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }

  .btn-close {
    padding: 0.5rem 0.5rem;
    margin: -0.5rem -0.5rem -0.5rem auto;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
  }
  .btn-close:hover {
    color: #000;
    text-decoration: none;
    opacity: .75;
  }
  .icon {
    margin-right: 0.5rem;
    font-size: 1.2em;
  }
</style>

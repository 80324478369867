<template>
  <div class="skeleton-container">
    <span class="placeholder-wave image-holder">
      <span class="placeholder col-12 image-holder"></span>
    </span>
    <div class="row">
      <span class="placeholder-wave pname-holder"> 
        <span class="placeholder col-6 pname-holder"></span>
        <span class="col-3"></span>
        <span class="col-3"></span>
      </span>
    </div>
    <div class="row">
      <span class="placeholder-wave"> 
        <span class="placeholder col-4 price-holder"></span>
      </span>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'TrailSkeleton'
  }
</script>

<style type="text/css">
  .skeleton-container {
    background-color: #fff;
    padding: 10px;
    border-radius: 2px;
    box-shadow: 0 .75rem 6rem rgba(56, 65, 74, 0.03);
    margin-top: 0.5em;
  }
  .image-holder {
    padding-bottom: 40%;
    padding-top: 35%;
    border-radius: 10px;
  }
  .pname-holder {
    padding-bottom: 1%;
    padding-top: 2%;
    border-radius: 10px;
  }
  .price-holder {
    padding-bottom: 1%;
    padding-top: 2%;
    border-radius: 10px;
  }
</style>

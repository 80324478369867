<template>
  <table class="table table-hover table-bordered table-condensed">
    <thead>
      <tr>
        <th>
          <span class="placeholder-wave"> <span class="placeholder col-12"></span></span>
        </th>
        <th>
          <span class="placeholder-wave"> <span class="placeholder col-12"></span></span>
        </th>
        <th>
          <span class="placeholder-wave"> <span class="placeholder col-12"></span></span>
        </th>
        <th>
          <span class="placeholder-wave"> <span class="placeholder col-12"></span></span>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="i in 5" :key="i">
        <th>
          <p class="placeholder-glow">
            <span class="placeholder col-12"></span>
          </p>
        </th>
        <th>
          <p class="placeholder-glow">
            <span class="placeholder col-12"></span>
          </p>
        </th>
        <th>
          <p class="placeholder-glow">
            <span class="placeholder col-12"></span>
          </p>
        </th>
        <th>
          <p class="placeholder-glow">
            <span class="placeholder col-12"></span>
          </p>
        </th>
      </tr>
    </tbody>
  </table>
</template>

<script>
  export default {
    name: 'TableSkeleton'
  }
</script>

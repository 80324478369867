<template>
  <div class="skeleton-container">
      <span class="placeholder col-12" style="padding: 6.45rem"></span>
      <span class="placeholder-wave"> <span class="placeholder col-12"></span></span>
  </div>
</template>

<script>
  export default {
    name: 'TrailSkeleton'
  }
</script>
<style type="text/css">
  .skeleton-container {
    background-color: #f2f2f2;
    padding: 10px;
    border-radius: 2px;
    box-shadow: 0 .75rem 6rem rgba(56, 65, 74, 0.03);
    margin-top: 0.5em;
  }
</style>

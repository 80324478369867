<template>
    <div id="wrapper">
      <Alert :type="this.alert_type" :message="this.alert_message" :visible="this.alert_visible" @dismiss="alertdismiss" />

      <Header title="Orders" />

      <Sidebar />

      <div class="content-page">
        <div class="content">
          <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body table-responsive">
                            <h4 class="mt-0 header-title">ALL ORDERS </h4>
                            <TableSkeleton v-if="getting_orders" />
                            <table v-else id="datatable" class="table table-bordered dt-responsive table-responsive nowrap">
                              <thead>
                                <tr>
                                    <th>No</th>
                                    <th>Invoice</th>
                                    <th>Products</th>
                                    <th>Client Name</th>
                                    <th>Client Phone</th>
                                    <th>Client Email</th>
                                    <th>Address</th>
                                    <th>Order Details</th>
                                    <th>Time</th>
                                    <th>Actions</th>
                                </tr>
                              </thead>
                              <tbody v-if="orders.length>0">
                                <tr v-for="(order, index) in orders" :key="order.id">
                                  <td>{{ index + 1 }}</td>
                                  <td>{{ order.invoice_number }}</td>
                                  <td>
                                    <button type="button" class="btn btn-primary btn-xs" data-bs-toggle="modal" data-bs-target="#order-products">{{ order.products.length }} Products</button>
                                  </td>
                                  <td>{{ order.client.client_names }}</td>
                                  <td>{{ order.client.phone }}</td>
                                  <td>{{ order.client.email }}</td>
                                  <td>{{ order.shipping_address }}</td>
                                  <td>{{ order.order_details }}</td>
                                  <td>{{ order.created_at }}</td>
                                  <td>
                                    <div class="btn-actions">
                                        <a 
                                          href="javascript:;"
                                        >
                                          <i className="mdi mdi-pencil text-primary mr-1 ml-10"></i>
                                        </a>
                                        <a href="javascript:;"><i className="mdi mdi-delete-forever-outline text-danger mr-1 ml-1"></i></a>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                              <tbody v-else-if="getting_order_errors!=''">
                                <tr>
                                  <td colspan="10"><h5 class="text-center text-danger">{{ getting_order_errors }}</h5></td>
                                </tr>
                              </tbody>
                              <tbody v-else>
                                <tr>
                                  <td colspan="10"><h5 class="text-center text-warning">No order available</h5></td>
                                </tr>
                              </tbody>
                            </table>
                        </div>
                    </div>
                   
                </div>
            </div>
          </div>
        </div>
      </div>


      <div id="order-products" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-md">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="standard-modal Label">ORDER PRODUCTS</h4>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body" v-if="opened_order!=''">
              <table id="datatable" class="table table-bordered dt-responsive table-responsive nowrap">
                <thead>
                  <tr>
                      <th>No</th>
                      <th>Image</th>
                      <th>Name</th>
                      <th>Price</th>
                      <th>Quantity</th>
                      <th>Status</th>
                      <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(order_product, index) in opened_order.order_products" :key="order_product.id">
                    <td>{{ index + 1 }}</td>
                    <td><img :src="order_product.product.images[0].image_path" style="height: 3.5em; width: 3.5em; object-fit: contain;" /></td>
                    <td>{{ order_product.product.product_name }}</td>
                    <td>{{ order_product.amount }} RWF</td>
                    <td>{{ order_product.quantity }} {{ order_product.product.unit.unit_name }}</td>
                    <td>{{ order_product.order_status }}</td>
                    <td>
                      <div class="btn-actions">
                          <a 
                            href="javascript:;"
                          >
                            <i className="mdi mdi-pencil text-primary mr-1 ml-10"></i>
                          </a>
                          <a href="javascript:;"><i className="mdi mdi-delete-forever-outline text-danger mr-1 ml-1"></i></a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div><!-- /.modal-content -->
        </div><!-- /.modal-dialog -->
      </div><!-- /.modal -->
    </div>
</template>

<script>
  import axios from '../../helpers/axios';
  import Header from '../../components/Header'; 
  import Sidebar from '../../components/Sidebar';  
  import Alert from '../../components/Alert';
  import TableSkeleton from '../../components/TableSkeleton';

  export default {
    name: 'OrdersPage',
    components: {
      Header,
      Sidebar,
      Alert,
      TableSkeleton
    },
    data() {
      return {
        user: null,

        orders: [],

        opened_order: '',

        save_loading: false,

        getting_orders: false,
        getting_order_errors: "",

        alert_type: '',
        alert_message: '',
        alert_visible: false,
      }
    },
    methods: {
      updateOrderStatus(order,status) {
        this.order.status = status;

        this.save_loading = true;

        axios.post('/api/orders', order)
          .then(response => {
            if(response.data.status) {
              this.alertshow('success','Status Changed Successfully');
              this.getOrders();
            }
            else {
              this.alertshow('danger',response.data.message);
            }
            this.save_loading = false;
          })
          .catch(error => {
            let error_message = error.response.data.message;
            for (const property in error.response.data.errors) {
              error_message += ". "+error.response.data.errors[property];
            }
            this.alertshow('danger',error_message);
            this.save_loading = false;
          });
      },
      getOrders() {
        this.getting_orders = true;
        axios.get('api/orders')
          .then(response => {
            if(response.data.status) {
              this.orders = response.data.message;
            }
            else {
              this.alertshow('danger',response.data.message);
              this.getting_order_errors = response.data.message;
            }
            this.getting_orders = false;
          })
          .catch(error => {
            let error_message = error.response.data.message;
            this.getting_order_errors = error.response.data.message;
            for (const property in error.response.data.errors) {
              error_message += ". "+error.response.data.errors[property];
            }
            this.alertshow('danger',error_message);
            this.getting_orders = false;
          });
      },
      alertdismiss() {
        this.alert_visible = false;
        this.alert_type = '';
        this.alert_message = '';
      },
      alertshow(type,message) {
        this.alert_visible = true;
        this.alert_type = type;
        this.alert_message = message;
      }
    },
    created() {
      this.user = JSON.parse(localStorage.getItem('user'));
      this.getOrders();
    }
  };
</script>
<template>
  
  <div id="page">
    
  <WebsiteHeader />
  <!-- /header -->

  <main>
    <div class="top_banner">
      <div class="opacity-mask d-flex align-items-center" data-opacity-mask="rgba(0, 0, 0, 0.3)">
        <div class="container">
          <div class="breadcrumbs">
            <ul>
              <li><a href="#">Home</a></li>
              <li><a href="#">Category</a></li>
              <li>Mountain Bike</li>
            </ul>
          </div>
          <h1>Bicycles - Grid listing</h1>
        </div>
      </div>
      <img src="/assets/website/img/slides/slide1.jpg" class="img-fluid" alt="">
    </div>
    <!-- /top_banner -->
      <div id="stick_here"></div>   
      <div class="toolbox elemento_stick">
        <div class="container">
        <ul class="clearfix">
          <li>
            <div class="sort_select">
              <select name="sort" id="sort">
                <option value="popularity" selected="selected">Sort by popularity</option>
                <option value="rating">Sort by average rating</option>
                <option value="date">Sort by newness</option>
                <option value="price">Sort by price: low to high</option>
                <option value="price-desc">Sort by price: high to </option>
              </select>
            </div>
          </li>
          <li>
            <a href="#0"><i class="ti-view-grid"></i></a>
            <a href="#"><i class="ti-view-list"></i></a>
          </li>
          <li>
            <a href="#0" class="open_filters">
              <i class="ti-filter"></i><span>Filters</span>
            </a>
          </li>
        </ul>
        </div>
      </div>
      <!-- /toolbox -->
      
      <div class="container margin_30">
      
      <div class="row">
        <aside class="col-lg-3" id="sidebar_fixed">
            <div class="filter_col">
                <div class="inner_bt"><a href="#" class="open_filters"><i class="ti-close"></i></a></div>
                <div class="filter_type version_2">
                    <h4><a href="#filter_1" data-toggle="collapse" class="opened">Bike Types</a></h4>
                    <div class="collapse show" id="filter_1">
                        <ul>
                            <li>
                                <label class="container_check">Road Bicycle <small>12</small>
                                    <input type="checkbox">
                                    <span class="checkmark"></span>
                                </label>
                            </li>
                            <li>
                                <label class="container_check">Hybrid Bicycle <small>24</small>
                                    <input type="checkbox">
                                    <span class="checkmark"></span>
                                </label>
                            </li>
                            <li>
                                <label class="container_check">Mountain Bike <small>23</small>
                                    <input type="checkbox">
                                    <span class="checkmark"></span>
                                </label>
                            </li>
                            <li>
                                <label class="container_check">Cyclist Bicycle <small>11</small>
                                    <input type="checkbox">
                                    <span class="checkmark"></span>
                                </label>
                            </li>
                        </ul>
                    </div>
                    <!-- /filter_type -->
                </div>
                <!-- /filter_type -->
                <div class="filter_type version_2">
                    <h4><a href="#filter_2" data-toggle="collapse" class="opened">Categories</a></h4>
                    <div class="collapse show" id="filter_2">
                        <ul>
                            <li>
                                <label class="container_check">Bikes <small>12</small>
                                    <input type="checkbox">
                                    <span class="checkmark"></span>
                                </label>
                            </li>
                            <li>
                                <label class="container_check">Spares <small>24</small>
                                    <input type="checkbox">
                                    <span class="checkmark"></span>
                                </label>
                            </li>
                            <li>
                                <label class="container_check">Accessories <small>23</small>
                                    <input type="checkbox">
                                    <span class="checkmark"></span>
                                </label>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- /filter_type -->
                <div class="filter_type version_2">
                    <h4><a href="#filter_4" data-toggle="collapse" class="closed">Price</a></h4>
                    <div class="collapse" id="filter_4">
                        <ul>
                            <li>
                                <label class="container_check">$0 — $50<small>11</small>
                                    <input type="checkbox">
                                    <span class="checkmark"></span>
                                </label>
                            </li>
                            <li>
                                <label class="container_check">$50 — $100<small>08</small>
                                    <input type="checkbox">
                                    <span class="checkmark"></span>
                                </label>
                            </li>
                            <li>
                                <label class="container_check">$100 — $150<small>05</small>
                                    <input type="checkbox">
                                    <span class="checkmark"></span>
                                </label>
                            </li>
                            <li>
                                <label class="container_check">$150 — $200<small>18</small>
                                    <input type="checkbox">
                                    <span class="checkmark"></span>
                                </label>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- /filter_type -->
                <div class="buttons">
                    <a href="#0" class="btn_1">Filter</a> <a href="#0" class="btn_1 gray">Reset</a>
                </div>
            </div>
        </aside>
        <!-- /col -->
        <div class="col-lg-9">
            <div v-if="product_loading" class="row small-gutters">
                <div v-for="i in 6" :key="i" class="col-6 col-md-4">
                  <ProductSkeleton />
                </div>
            </div>
            <div v-else-if="products.length > 0" class="row small-gutters">
                <div class="col-6 col-md-4" v-for="(product, index) in products" :key="index">
                  <div class="grid_item">
                    <span class="ribbon off">-30%</span>
                    <figure>
                      <router-link to="product-details/btx-bike-345">
                        <img class="img-fluid lazy" src="/assets/website/img/products/product_placeholder_square_medium.jpg" data-src="/assets/website/img/slides/slide4.jpg" alt="">
                      </router-link>
                    </figure>
                    <router-link to="product-details/btx-bike-345">
                      <h3>{{ product.product_name }}</h3>
                    </router-link>
                    <div class="price_box">
                      <span class="new_price">{{ product.buy_price.toLocaleString() }} Rwf</span>
                      <!-- <span class="old_price">$60.00</span> -->
                    </div>
                    <ul>
                      <li><a href="#0" class="tooltip-1" data-toggle="tooltip" data-placement="left" title="Add to favorites"><i class="ti-heart"></i><span>Add to favorites</span></a></li>
                      <li><a href="#0" class="tooltip-1" data-toggle="tooltip" data-placement="left" title="Add to compare"><i class="ti-control-shuffle"></i><span>Add to compare</span></a></li>
                      <li><a href="#0" class="tooltip-1" data-toggle="tooltip" data-placement="left" title="Add to cart"><i class="ti-shopping-cart"></i><span>Add to cart</span></a></li>
                    </ul>
                  </div>
                  <!-- /grid_item -->
                </div>
            </div>
            <div v-else class="container margin_90_65 result">
                <div class="main_title">
                    <p><b>Result Not Found!! </b></p>
                </div>
            </div>
        </div>
        <!-- /col -->
      </div>
      <!-- /row -->     
        
    </div>
    <!-- /container -->
  </main>
  <!-- /main -->
    
  <WebsiteFooter />
  <!--/footer-->
  </div>
  <!-- page -->
  
  <div id="toTop"></div><!-- Back to top button -->
</template>
<script>
  import axios from '../helpers/axios';
  import WebsiteHeader from '../components/WebsiteHeader';
  import WebsiteFooter from '../components/WebsiteFooter';
  import ProductSkeleton from '../components/ProductSkeleton';
  export default {
    name: 'ItemsPage',
    components: {
      WebsiteHeader,
      WebsiteFooter,
      ProductSkeleton
    },
    data(){
        return{
            products:[],
            product_loading:false,

            product_msg:'',
        }
    },
    watch: {
      '$route.params.category_id': function(newCategoryId) {
        this.fetchProducts(newCategoryId);
      }
    },
    created(){
        const category_id = this.$route.params.category_id;
        this.fetchProducts(category_id);
    },
    mounted() { 
        // Load specific scripts
        if (!document.querySelector('script[src="/assets/website/js/sticky_sidebar.min.js"]')) {
            const stickySidebarScripts = document.createElement("script");
            stickySidebarScripts.src = "/assets/website/js/sticky_sidebar.min.js";
            document.head.appendChild(stickySidebarScripts);
        }

        if (!document.querySelector('script[src="/assets/website/js/specific_listing.js"]')) {
            const specificListingScripts = document.createElement("script");
            specificListingScripts.src = "/assets/website/js/specific_listing.js";
            document.head.appendChild(specificListingScripts);
        }

        // Load specific css
        if (!document.querySelector('link[href="/assets/website/css/listing.css"]')) {
            const head = document.head;
            const listing = document.createElement('link');

            listing.rel = 'stylesheet';
            listing.href = '/assets/website/css/listing.css';
            head.appendChild(listing);
        }
    },
    methods:{
        fetchProducts(category_id) {
          this.product_msg = '';
          this.product_loading = true;
          axios.get(`api/products?category_id=${category_id}`).then(response=>{
            this.products = response.data.message;
            this.product_loading = false;
          })
          .catch(error=>{
            this.product_msg = error;
            this.product_loading = false;
          })
        }

    }
  }
</script>
<style>
    .result{
        background-color: #f7f7f7;
        padding-top: 5%;
        padding-bottom: 1%;
    }
</style>


<template>
  
  <div id="page">
    
  <WebsiteHeader />
  <!-- /header -->
    
  <main>
    <div id="carousel-home">
      <div class="owl-carousel owl-theme">
        <div class="owl-slide cover" style="background-image: url(/assets/website/img/slides/slide1.jpg);">
          <div class="opacity-mask d-flex align-items-center" data-opacity-mask="rgba(0, 0, 0, 0.5)">
            <div class="container">
              <div class="row justify-content-center justify-content-md-end">
                <div class="col-lg-6 static">
                  <div class="slide-text text-right white">
                    <h2 class="owl-slide-animated owl-slide-title">Discover the joy <br>of cycling</h2>
                    <p class="owl-slide-animated owl-slide-subtitle">
                      with our stylish and affordable bicycles. 
                    </p>
                    <div class="owl-slide-animated owl-slide-cta"><router-link class="btn_1" to="/products" role="button">Shop Now</router-link></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--/owl-slide-->
        <div class="owl-slide cover" style="background-image: url(/assets/website/img/slides/slide2.jpg);">
          <div class="opacity-mask d-flex align-items-center" data-opacity-mask="rgba(0, 0, 0, 0.5)">
            <div class="container">
              <div class="row justify-content-center justify-content-md-start">
                <div class="col-lg-6 static">
                  <div class="slide-text white">
                    <h2 class="owl-slide-animated owl-slide-title">Find your <br>perfect ride today</h2>
                    <p class="owl-slide-animated owl-slide-subtitle">
                      – shop our collection of premium bicycles.
                    </p>
                    <div class="owl-slide-animated owl-slide-cta"><router-link class="btn_1" to="/products" role="button">Shop Now</router-link></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--/owl-slide-->
        <div class="owl-slide cover" style="background-image: url(/assets/website/img/slides/slide4.jpg);">
          <div class="opacity-mask d-flex align-items-center" data-opacity-mask="rgba(255, 255, 255, 0.5)">
            <div class="container">
              <div class="row justify-content-center justify-content-md-start">
                <div class="col-lg-12 static">
                  <div class="slide-text text-center black">
                    <h2 class="owl-slide-animated owl-slide-title">Experience <br>the thrill of cycling</h2>
                    <p class="owl-slide-animated owl-slide-subtitle">
                      with our range of sleek and stylish bicycles.
                    </p>
                    <div class="owl-slide-animated owl-slide-cta"><router-link class="btn_1" to="/products" role="button">Shop Now</router-link></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--/owl-slide-->
        </div>
      </div>
      <div id="icon_drag_mobile"></div>
    </div>
    <!--/carousel-->

    <ul id="banners_grid" class="clearfix">
      <li v-for="(bike_type, index) in bike_types.slice(0, 3)" :key="index">
        <router-link :to="'/bike-types/' + bike_type.type_name" class="img_container">
          <img src="/assets/website/img/slides/slide5.jpg" data-src="/assets/website/img/slides/slide5.jpg" alt="" class="lazy">
          <div class="short_info opacity-mask" data-opacity-mask="rgba(0, 0, 0, 0.5)">
            <h3>{{bike_type.type_name}}</h3>
            <div><span class="btn_1">Shop Now</span></div>
          </div>
        </router-link>
      </li>
      <!-- <li>
        <a href="#0" class="img_container">
          <img src="/assets/website/img/banners_cat_placeholder.jpg" data-src="/assets/website/img/slides/slide2.jpg" alt="" class="lazy">
          <div class="short_info opacity-mask" data-opacity-mask="rgba(0, 0, 0, 0.5)">
            <h3>Hybrid Bicycle</h3>
            <div><span class="btn_1">Shop Now</span></div>
          </div>
        </a>
      </li>
      <li>
        <a href="#0" class="img_container">
          <img src="/assets/website/img/banners_cat_placeholder.jpg" data-src="/assets/website/img/slides/slide6.jpg" alt="" class="lazy">
          <div class="short_info opacity-mask" data-opacity-mask="rgba(0, 0, 0, 0.5)">
            <h3>Mountain Bike</h3>
            <div><span class="btn_1">Shop Now</span></div>
          </div>
        </a>
      </li> -->
    </ul>
    <!--/banners_grid -->
    
    <div class="container margin_60_35">
      <div class="main_title">
        <h2>Top Selling</h2>
        <span>Products</span>
        <p>Get out and explore with our top-rated bicycles – perfect for any adventure.</p>
      </div>
      <div v-if="getting_products" class="row small-gutters">
        <div v-for="i in 8" :key="i" class="col-6 col-md-4 col-xl-3">
          <ProductSkeleton />
        </div>
      </div>
      <div v-else class="row small-gutters">
        <div v-for="(product, index) in products" :key="index" class="col-6 col-md-4 col-xl-3">
          <div class="grid_item">
            <figure>
              <span class="ribbon off">-30%</span>
              <router-link :to="'/product-details/' + product.slug">
                <!-- <img class="img-fluid lazy" :src="product.images[0].image_path" alt=""> -->
                <img class="img-fluid lazy" src="/assets/website/img/products/product_3.jpg" data-src="/assets/website/img/products/product_3.jpg" alt="">
                <img class="img-fluid lazy" src="/assets/website/img/products/product_3.jpg" data-src="/assets/website/img/products/product_3.jpg" alt="">
                <!-- <div data-countdown="2019/05/15" class="countdown"></div> -->
              </router-link>
            </figure>

            <div class="rating">
              <i v-for="n in 5" :key="n" class="icon-star" :class="{ 'voted': n <= product.id }"></i>
            </div>
            <router-link :to="'/product-details/' + product.id">
              <h3><b>{{ product.product_name }}</b></h3>
            </router-link>
            <div class="price_box">
              <span class="new_price">{{ product.buy_price.toLocaleString() }} Rwf</span>
              <!-- <span v-if="product.old_price" class="old_price">{{ product.old_price }}</span> -->
            </div>
            <ul>
              <li><a href="#0" class="tooltip-1" data-toggle="tooltip" data-placement="left" title="Add to favorites"><i class="ti-heart"></i><span>Add to favorites</span></a></li>
              <li><a href="#0" class="tooltip-1" data-toggle="tooltip" data-placement="left" title="Add to compare"><i class="ti-control-shuffle"></i><span>Add to compare</span></a></li>
              <li><a href="#0" class="tooltip-1" data-toggle="tooltip" data-placement="left" title="Add to cart"><i class="ti-shopping-cart"></i><span>Add to cart</span></a></li>
            </ul>
          </div>
        </div>
      </div>

      <!-- /row -->
    </div>
    <!-- /container -->

    <div class="featured lazy" data-bg="url(/assets/website/img/slides/slide5.jpg)">
      <div class="opacity-mask d-flex align-items-center" data-opacity-mask="rgba(0, 0, 0, 0.5)">
        <div class="container margin_60">
          <div class="row justify-content-center justify-content-md-start">
            <div class="col-lg-6 wow" data-wow-offset="150">
              <h3>The Ride<br> Bicycle Shop in Kigali</h3>
              <p>Invest in your health and well-being with our range of bicycles.</p>
              <div class="feat_text_block">
                <div class="price_box">
                  <span class="new_price">$90.00</span>
                  <span class="old_price">$170.00</span>
                </div>
                <router-link class="btn_1" to="/products" role="button">Shop Now</router-link>
             </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /featured -->

    <div class="container margin_60_35">
      <div class="main_title">
        <h2>Featured</h2>
        <span>Products</span>
        <p>Upgrade your ride – browse our selection of cutting-edge bicycles today.</p>
      </div>
      <div class="owl-carousel owl-theme products_carousel">
        <!-- v-for="(product, index) in products" :key="index" -->
        <!-- <div class="item" v-for="(product, index) in products" :key="product.id">
          <div class="grid_item product-{{product.id}}">
            <span class="ribbon new">New</span>
            <figure>
              <router-link to="/product-details/baby-bike-234">
                <img class="owl-lazy" src="/assets/website/img/products/product_1.jpg" data-src="/assets/website/img/products/product_1.jpg" alt="">
              </router-link>
            </figure>
            <div class="rating"><i class="icon-star voted"></i><i class="icon-star voted"></i><i class="icon-star voted"></i><i class="icon-star voted"></i><i class="icon-star"></i></div>
            <router-link to="/product-details/baby-bike-234">
              <h3>Trek Domane</h3>
            </router-link>
            <div class="price_box">
              <span class="new_price">$110.00</span>
            </div>
            <ul>
              <li><a href="#0" class="tooltip-1" data-toggle="tooltip" data-placement="left" title="Add to favorites"><i class="ti-heart"></i><span>Add to favorites</span></a></li>
              <li><a href="#0" class="tooltip-1" data-toggle="tooltip" data-placement="left" title="Add to compare"><i class="ti-control-shuffle"></i><span>Add to compare</span></a></li>
              <li><a href="#0" class="tooltip-1" data-toggle="tooltip" data-placement="left" title="Add to cart"><i class="ti-shopping-cart"></i><span>Add to cart</span></a></li>
            </ul>
          </div>
        </div> -->
        <div class="item" v-for="i in 6" :key="i">
          <div>
            <div class="grid_item">
              <span class="ribbon new">New</span>
              <figure>
                <router-link to="/product-details/baby-bike-234">
                  <img class="owl-lazy" src="/assets/website/img/products/product_1.jpg" data-src="/assets/website/img/products/product_1.jpg" alt="">
                </router-link>
              </figure>
              <div class="rating"><i class="icon-star voted"></i><i class="icon-star voted"></i><i class="icon-star voted"></i><i class="icon-star voted"></i><i class="icon-star"></i></div>
              <router-link to="/product-details/baby-bike-234">
                <h3>Trek Domane</h3>
              </router-link>
              <div class="price_box">
                <span class="new_price">$110.00</span>
              </div>
              <ul>
                <li><a href="#0" class="tooltip-1" data-toggle="tooltip" data-placement="left" title="Add to favorites"><i class="ti-heart"></i><span>Add to favorites</span></a></li>
                <li><a href="#0" class="tooltip-1" data-toggle="tooltip" data-placement="left" title="Add to compare"><i class="ti-control-shuffle"></i><span>Add to compare</span></a></li>
                <li><a href="#0" class="tooltip-1" data-toggle="tooltip" data-placement="left" title="Add to cart"><i class="ti-shopping-cart"></i><span>Add to cart</span></a></li>
              </ul>
            </div>
          </div>
        </div>
      
        <div class="item">
          <div class="grid_item">
            <span class="ribbon new">New</span>
            <figure>
              <router-link to="/product-details/baby-bike-234">
                <img class="owl-lazy" src="/assets/website/img/products/product_placeholder_square_medium.jpg" data-src="/assets/website/img/products/product_2.jpg" alt="">
              </router-link>
            </figure>
            <div class="rating"><i class="icon-star voted"></i><i class="icon-star voted"></i><i class="icon-star voted"></i><i class="icon-star voted"></i><i class="icon-star"></i></div>
            <router-link to="/product-details/baby-bike-234">
              <h3>Specialized Roubaix</h3>
            </router-link>
            <div class="price_box">
              <span class="new_price">$140.00</span>
            </div>
            <ul>
              <li><a href="#0" class="tooltip-1" data-toggle="tooltip" data-placement="left" title="Add to favorites"><i class="ti-heart"></i><span>Add to favorites</span></a></li>
              <li><a href="#0" class="tooltip-1" data-toggle="tooltip" data-placement="left" title="Add to compare"><i class="ti-control-shuffle"></i><span>Add to compare</span></a></li>
              <li><a href="#0" class="tooltip-1" data-toggle="tooltip" data-placement="left" title="Add to cart"><i class="ti-shopping-cart"></i><span>Add to cart</span></a></li>
            </ul>
          </div>
          <!-- /grid_item -->
        </div>
        <div class="item">
          <div class="grid_item">
            <span class="ribbon new">New</span>
            <figure>
              <router-link to="/product-details/baby-bike-234">
                <img class="owl-lazy" src="/assets/website/img/products/product_placeholder_square_medium.jpg" data-src="/assets/website/img/products/product_2.jpg" alt="">
              </router-link>
            </figure>
            <div class="rating"><i class="icon-star voted"></i><i class="icon-star voted"></i><i class="icon-star voted"></i><i class="icon-star voted"></i><i class="icon-star"></i></div>
            <router-link to="/product-details/baby-bike-234">
              <h3>Specialized Roubaix</h3>
            </router-link>
            <div class="price_box">
              <span class="new_price">$140.00</span>
            </div>
            <ul>
              <li><a href="#0" class="tooltip-1" data-toggle="tooltip" data-placement="left" title="Add to favorites"><i class="ti-heart"></i><span>Add to favorites</span></a></li>
              <li><a href="#0" class="tooltip-1" data-toggle="tooltip" data-placement="left" title="Add to compare"><i class="ti-control-shuffle"></i><span>Add to compare</span></a></li>
              <li><a href="#0" class="tooltip-1" data-toggle="tooltip" data-placement="left" title="Add to cart"><i class="ti-shopping-cart"></i><span>Add to cart</span></a></li>
            </ul>
          </div>
          <!-- /grid_item -->
        </div>
      </div>
      <!-- /products_carousel -->
    </div>
    <!-- /container -->
    
    <div class="feat">
      <div class="container">
        <ul>
          <li>
            <div class="box">
              <i class="ti-gift"></i>
              <div class="justify-content-center">
                <h3>Free Shipping</h3>
                <p>For all oders over $99</p>
              </div>
            </div>
          </li>
          <li>
            <div class="box">
              <i class="ti-wallet"></i>
              <div class="justify-content-center">
                <h3>Secure Payment</h3>
                <p>100% secure payment</p>
              </div>
            </div>
          </li>
          <li>
            <div class="box">
              <i class="ti-headphone-alt"></i>
              <div class="justify-content-center">
                <h3>24/7 Support</h3>
                <p>Online top support</p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

  </main>
  <!-- /main -->
    
  <WebsiteFooter />
  <!--/footer-->
  </div>
  <!-- page -->
  
  <div id="toTop"></div><!-- Back to top button -->
</template>
<script>
  import axios from '../helpers/axios';
  import WebsiteHeader from '../components/WebsiteHeader';
  import WebsiteFooter from '../components/WebsiteFooter';
  import ProductSkeleton from '../components/ProductSkeleton';
  export default {
    name: 'HomePage',
    components: {
      WebsiteHeader,
      WebsiteFooter,
      ProductSkeleton
    },
    data(){
      return {
        products:[],
        bike_types:[],
        getting_products: false,
        getting_product_errors: "",
      }
    },
    created(){
      this.getProducts();
      this.getBikeTypes();
    },
    mounted() { 
      // Load specific scripts
      if (!document.querySelector('script[src="/assets/website/js/carousel-home.min.js"]')) {
        const carouselScripts = document.createElement("script");
        carouselScripts.src = "/assets/website/js/carousel-home.min.js";
        document.head.appendChild(carouselScripts);
      }
      if (!document.querySelector('link[href="/assets/website/css/home_1.css"]')) {
        const head = document.head;
        const home_1 = document.createElement('link');

        home_1.rel = 'stylesheet';
        home_1.href = '/assets/website/css/home_1.css';
        head.appendChild(home_1);
      }
    },
    methods: {
      getProducts() {
        this.getting_products = true;
        axios.get('api/products')
          .then(response => {
            if(response.data.status) {
              this.products = response.data.message;
            }
            else {
              this.alertshow('danger',response.data.message);
              this.getting_product_errors = response.data.message;
            }
            this.getting_products = false;
          })
          .catch(error => {
            let error_message = error.response.data.message;
            this.getting_product_errors = error.response.data.message;
            for (const property in error.response.data.errors) {
              error_message += ". "+error.response.data.errors[property];
            }
            this.alertshow('danger',error_message);
            this.getting_products = false;
          });
      },
      getBikeTypes() {
        this.getting_bike_types = true;
        this.getting_bike_type_errors = '';
        axios.get('api/bike_types')
          .then(response => {
            if(response.data.status) {
              // Shuffle the bike types array
              const shuffled = response.data.message.sort(() => 0.5 - Math.random());
              // Get the first three elements of the shuffled array
              this.bike_types = shuffled.slice(0, 3);
            }
            else {
              this.alertshow('danger',response.data.message);
              this.getting_bike_type_errors = response.data.message;
            }
            this.getting_bike_types = false;
          })
          .catch(error => {
            let error_message = error.response.data.message;
            this.getting_bike_type_errors = error.response.data.message;
            for (const property in error.response.data.errors) {
              error_message += ". "+error.response.data.errors[property];
            }
            this.alertshow('danger',error_message);
            this.getting_bike_types = false;
          });
      }

    }
  }
</script>


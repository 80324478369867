<template>
  <div>
    <header class="version_2">
      <div class="layer"></div><!-- Mobile menu overlay mask -->
      <div class="top_line version_1 plus_select">
        <div class="container">
          <div class="row d-flex align-items-center">
            <div class="col-sm-6 col-5">
            </div>
            <div class="col-sm-6 col-7">
              <ul class="top_links">
                <li>
                  <div class="styled-select lang-selector">
                    <select>
                      <option value="English" selected>English</option>
                    </select>
                  </div>
                </li>
                <li><div class="styled-select currency-selector">
                  <select>
                    <option value="RWF" selected="">RWF</option>
                    <option value="Euro">Euro</option>
                  </select>
                </div></li>
              </ul>
            </div>
          </div>
          <!-- End row -->
        </div>
        <!-- End container-->
      </div>
      <div class="main_header Sticky">
        <div class="container">
          <div class="row small-gutters">
            <div class="col-xl-3 col-lg-3 d-lg-flex align-items-center">
              <div id="logo">
                <router-link to="/"><img src="/assets/website/img/ride_logo.jpg" alt="" width="100" height="55"></router-link>
              </div>
            </div>
            <nav class="col-xl-6 col-lg-7">
              <a class="open_close" href="javascript:void(0);">
                <div class="hamburger hamburger--spin">
                  <div class="hamburger-box">
                    <div class="hamburger-inner"></div>
                  </div>
                </div>
              </a>
              <!-- Mobile menu button -->
              <div class="main-menu">
                <div id="header_menu">
                  <router-link to="/"><img src="/assets/website/img/ride_logo.jpg" alt="" width="100" height="35"></router-link>
                  <a href="#" class="open_close" id="close_in"><i class="ti-close"></i></a>
                </div>
                <ul>
                  <li>
                    <router-link to="/">Home</router-link>
                  </li>
                  <li>
                    <router-link to="/products/1">Bikes</router-link>
                  </li>
                  <li>
                    <router-link to="/products/2">Spares</router-link>
                  </li>
                  <li>
                    <router-link to="/products/3">Accessories</router-link>
                  </li>
                  <!-- <li>
                    <router-link to="/rent">Rent</router-link>
                  </li> -->
                  <li>
                    <router-link to="/road-trail">Road Trail</router-link>
                  </li>
                  <li>
                    <router-link to="/about">About</router-link>
                  </li>
                  <li>
                    <router-link to="/contacts">Contact</router-link>
                  </li>
                </ul>
              </div>
              <!--/main-menu -->
            </nav>
            <div class="col-xl-3 col-lg-2 d-lg-flex align-items-center justify-content-end text-right">
              <ul class="top_tools">
                <li>
                  <div class="dropdown dropdown-cart">
                    <router-link to="/cart" class="cart_bt"><strong>2</strong></router-link>
                    <div class="dropdown-menu">
                      <ul>
                        <li>
                          <router-link to="/product-details/btx-bike-345">
                            <figure><img src="/assets/website/img/products/product_placeholder_square_small.jpg" data-src="/assets/website/img/products/product_1.JPG" alt="" width="50" height="50" class="lazy"></figure>
                            <strong><span>1x Trek Domane</span>$90.00</strong>
                          </router-link>
                          <a href="javascript:;" class="action"><i class="ti-trash"></i></a>
                        </li>
                        <li>
                          <router-link to="/product-details/btx-bike-345">
                            <figure><img src="/assets/website/img/products/product_placeholder_square_small.jpg" data-src="/assets/website/img/products/product_1.jpg" alt="" width="50" height="50" class="lazy"></figure>
                            <strong><span>1x Specialized Roubaix</span>$110.00</strong>
                          </router-link>
                          <a href="javascript:;" class="action"><i class="ti-trash"></i></a>
                        </li>
                      </ul>
                      <div class="total_drop">
                        <div class="clearfix"><strong>Total</strong><span>$200.00</span></div>
                        <router-link to="/cart" class="btn_1 outline">View Cart</router-link>
                        <router-link to="/checkout" class="btn_1">Checkout</router-link>
                      </div>
                    </div>
                  </div>
                  <!-- /dropdown-cart-->
                </li>
                <li>
                  <a href="javascript:;" class="wishlist"><span>Wishlist</span></a>
                </li>
                <li>
                  <div class="dropdown dropdown-access">
                    <router-link to="/auth" class="access_link"><span>Account</span></router-link>
                    <div class="dropdown-menu">
                      <router-link to="/auth" class="btn_1">Sign In or Sign Up</router-link>
                      <ul>
                        <li>
                          <router-link to="/track-order"><i class="ti-truck"></i>Track your Order</router-link>
                        </li>
                        <li>
                          <router-link to="/auth"><i class="ti-package"></i>My Orders</router-link>
                        </li>
                        <li>
                          <router-link to="/auth"><i class="ti-user"></i>My Profile</router-link>
                        </li>
                        <li>
                          <router-link to="/help"><i class="ti-help-alt"></i>Help and Faq</router-link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <!-- /dropdown-access-->
                </li>
                <li>
                  <a href="javascript:void(0);" class="search_panel"><span>Search</span></a>
                </li>
              
              </ul>
            </div>
          </div>
          <!-- /row -->
        </div>
      </div>
      <!-- /main_header -->
    </header>
  
    <div class="top_panel">
      <div class="container header_panel">
        <a href="javascript:;" class="btn_close_top_panel"><i class="ti-close"></i></a>
        <small>What are you looking for?</small>
      </div>
      <!-- /header_panel -->
      
      <div class="container">
        <div class="search-input">
            <input type="text" placeholder="Search over 10.000 products...">
            <button type="submit"><i class="ti-search"></i></button>
          </div>
      </div>
      <!-- /related -->
    </div>
    <!-- /search_panel -->
  </div>
</template>

<script>
  export default {
    name: 'WebsiteHeader',
    mounted() { 
      // Load common scripts
      const commonScripts = document.createElement("script");
      commonScripts.src = "/assets/website/js/common_scripts.min.js";
      document.head.appendChild(commonScripts);

      const mainScripts = document.createElement("script");
      mainScripts.src = "/assets/website/js/main.js";
      document.head.appendChild(mainScripts);

      // Load main css
      if (!document.querySelector('link[href="/assets/website/css/bootstrap.custom.min.css"]')) {
        const head = document.head;
        const bootstrapCustom = document.createElement('link');

        bootstrapCustom.rel = 'stylesheet';
        bootstrapCustom.href = '/assets/website/css/bootstrap.custom.min.css';
        head.appendChild(bootstrapCustom);
      }
      // if (!document.querySelector('link[href="/assets/website/css/style.css"]')) {
      //   const head = document.head;
      //   const style = document.createElement('link');

      //   style.rel = 'stylesheet';
      //   style.href = '/assets/website/css/style.css';
      //   head.appendChild(style);
      // }
      if (!document.querySelector('link[href="/assets/website/css/custom.css"]')) {
        const head = document.head;
        const custom = document.createElement('link');

        custom.rel = 'stylesheet';
        custom.href = '/assets/website/css/custom.css';
        head.appendChild(custom);
      }
    }
  }
</script>

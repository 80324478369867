<template>
    <div id="wrapper">
      <Alert :type="this.alert_type" :message="this.alert_message" :visible="this.alert_visible" @dismiss="alertdismiss" />

      <Header title="Road Trails" />

      <Sidebar />

      <div class="content-page">
        <div class="content">
          <div class="container-fluid">

            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-body">
                    <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#add-trail">ADD ROAD TRAIL</button>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="getting_road_trails" class="row">
              <div v-for="i in 8" :key="i" class="col-xl-3">
                <TrailSkeleton />
              </div>
            </div>
            <div v-else-if="road_trails.length > 0" class="row">
              <div v-for="(road_trail, index) in road_trails" :key="index" class="col-xl-3">
                  <div class="card">
                    <div class="text-center card-body">
                        <div class="dropdown float-end">
                            <a href="#" class="dropdown-toggle arrow-none card-drop"  data-bs-toggle="dropdown" aria-expanded="false">
                                <i class="mdi mdi-dots-vertical"></i>
                            </a>
                            <div class="dropdown-menu dropdown-menu-end">
                                <a href="javascript:void(0);" class="dropdown-item text-primary"><i className="mdi mdi-pencil mr-1 ml-1"></i> Edit</a>
                                <a href="javascript:void(0);" class="dropdown-item text-danger"><i className="mdi mdi-delete-forever-outline mr-1 ml-1"></i> Delete</a>
                            </div>
                        </div>
                        <div>
                            <img :src="road_trail.trail_image_path" class="mt-2 mb-2" :alt="road_trail.trail_title" style="width: 100%;">

                            <a :href="road_trail.trail_link" target="_blank"><p class="text-muted font-13">{{road_trail.trail_title}}</p></a>
                        </div>

                    </div>
                  </div>
              </div>
            </div>
            <div v-else class="row">
              <div class="col-xl-12">
                <div class="card">
                  <div class="text-center card-body">
                    No trail saved yet
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div id="add-trail" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-md modal-right">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="standard-modalLabel">ADD NEW ROAD TRAIL</h4>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <form @submit.prevent="saveRoadTrail">
                <div class="form-group">
                  <label for="trail_road" class="form-label">Road Trail</label>
                  <input class="form-control" type="text" v-model="road_trail.trail_title" required="" placeholder="Ex: Kigali conventional - Nyabugogo">
                </div>
                <div class="form-group">
                  <label for="trail_link" class="form-label">Trail link</label>
                  <input class="form-control" type="text" v-model="road_trail.trail_link" required="" placeholder="Ex: https://trails.org/Zewiyzoie323">
                </div>
                <div class="col-md-12 mt-3">
                  <div class="images-container" v-if="road_trail.trail_image_preview!=''">
                    <div class="image-container">
                      <img :src="road_trail.trail_image_preview" height="150" alt="Trail Image">
                      <button type="button" class="delete-btn" @click="removeImage()">
                        <i class="fas fa-trash text-danger"></i>
                      </button>
                    </div>
                  </div>
                  <label class="upload-label" v-else>
                    <span class="upload-label-text">+ Add Image</span>
                    <span>Select Trail Image</span>
                    <input type="file" class="sr-only" accept="image/*" @change="onFileChange">
                  </label>
                  <p v-if="road_trail.trail_image_preview==''" class="error">
                    <span>You can't add a road trail without an image!</span><br />
                    Please add a road trail image.
                  </p>
                </div>
                <div class="mt-3 text-center">
                    <button v-if="save_loading" class="btn btn-primary" type="button">SAVING.....</button>
                    <button v-else class="btn btn-primary" type="submit">ADD ROAD TRAIL</button>
                </div>
              </form>
            </div>
          </div><!-- /.modal-content -->
        </div><!-- /.modal-dialog -->
      </div><!-- /.modal -->
    </div>
</template>

<script>
  import axios from '../../helpers/axios';
  import Header from '../../components/Header'; 
  import Sidebar from '../../components/Sidebar';  
  import Alert from '../../components/Alert';
  import TrailSkeleton from '../../components/TrailSkeleton';

  export default {
    name: 'RoadTrailsPage',
    components: {
      Header,
      Sidebar,
      Alert,
      TrailSkeleton
    },
    data() {
      return {
        user: null,

        road_trails: [],

        save_loading: false,

        getting_road_trails: false,
        getting_road_trail_errors: "",

        alert_type: '',
        alert_message: '',
        alert_visible: false,

        road_trail: {
          trail_title: '',
          trail_link: '',
          trail_image: '',
          trail_image_preview: '',
        },
      }
    },
    created() {
      this.user = JSON.parse(localStorage.getItem('user'));
      this.getRoadTrails();
    },
    methods: {
      onFileChange(e) {
        const file = e.target.files[0]
        const reader = new FileReader()
        reader.onload = e => {
          this.road_trail.trail_image_preview = e.target.result
        }
        reader.readAsDataURL(file)
        this.road_trail.trail_image = file
      },
      removeImage() {
        this.road_trail.trail_image = ""
        this.road_trail.trail_image_preview = ""
      },
      saveRoadTrail() {
        if (this.road_trail.trail_image == '') {
          this.alertshow('error','Road image is required. Please upload the image of road trail');
          return;
        }

        let formData = new FormData();
        formData.append('trail_title', this.road_trail.trail_title);
        formData.append('trail_link', this.road_trail.trail_link);
        formData.append('trail_image', this.road_trail.trail_image);

        this.save_loading = true;

        axios.post('/api/road_trails', formData)
          .then(response => {
            if(response.data.status) {
              this.alertshow('success','Road Trail Saved Successfully');
              this.getRoadTrails();
            }
            else {
              this.alertshow('danger',response.data.message);
            }
            this.save_loading = false;
          })
          .catch(error => {
            let error_message = error.response.data.message;
            for (const property in error.response.data.errors) {
              error_message += ". "+error.response.data.errors[property];
            }
            this.alertshow('danger',error_message);
            this.save_loading = false;
          });
      },
      getRoadTrails() {
        this.getting_road_trails = true;
        this.getting_road_trail_errors = '';
        axios.get('api/road_trails')
          .then(response => {
            if(response.data.status) {
              this.road_trails = response.data.message;
            }
            else {
              this.alertshow('danger',response.data.message);
              this.getting_road_trail_errors = response.data.message;
            }
            this.getting_road_trails = false;
          })
          .catch(error => {
            let error_message = error.response.data.message;
            this.getting_road_trail_errors = error.response.data.message;
            for (const property in error.response.data.errors) {
              error_message += ". "+error.response.data.errors[property];
            }
            this.alertshow('danger',error_message);
            this.getting_road_trails = false;
          });
      },
      alertdismiss() {
        this.alert_visible = false;
        this.alert_type = '';
        this.alert_message = '';
      },
      alertshow(type,message) {
        this.alert_visible = true;
        this.alert_type = type;
        this.alert_message = message;
      }
    }
  };
</script>

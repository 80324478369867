<template>
    <div id="wrapper">
      <Alert :type="this.alert_type" :message="this.alert_message" :visible="this.alert_visible" @dismiss="alertdismiss" />

      <Header title="Clients" />

      <Sidebar />

      <div class="content-page">
        <div class="content">
          <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-body table-responsive">
                            <h4 class="mt-0 header-title">ALL CLIENTS</h4>
                            <TableSkeleton v-if="getting_clients" />
                            <table v-else id="datatable" class="table table-bordered dt-responsive table-responsive nowrap">
                              <thead>
                                <tr>
                                    <th>No</th>
                                    <th>Names</th>
                                    <th>Phone</th>
                                    <th>Email</th>
                                    <th>Added On</th>
                                    <th>Actions</th>
                                </tr>
                              </thead>
                              <tbody v-if="clients.length>0">
                                <tr v-for="(client, index) in clients" :key="client.id">
                                  <td>{{ index + 1 }}</td>
                                  <td>{{ client.client_names }}</td>
                                  <td>{{ client.phone }}</td>
                                  <td>{{ client.email }}</td>
                                  <td>{{ client.created_at }}</td>
                                  <td>
                                    <div class="btn-actions">
                                        <a 
                                          href="javascript:;"
                                        >
                                          <i className="mdi mdi-pencil text-primary mr-1 ml-10"></i>
                                        </a>
                                        <a href="javascript:;"><i className="mdi mdi-delete-forever-outline text-danger mr-1 ml-1"></i></a>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                              <tbody v-else-if="getting_client_errors!=''">
                                <tr>
                                  <td colspan="6"><h5 class="text-center text-danger">{{ getting_client_errors }}</h5></td>
                                </tr>
                              </tbody>
                              <tbody v-else>
                                <tr>
                                  <td colspan="6"><h5 class="text-center text-warning">No client available</h5></td>
                                </tr>
                              </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
</template>

<script>
  import axios from '../../helpers/axios';
  import Header from '../../components/Header'; 
  import Sidebar from '../../components/Sidebar';  
  import Alert from '../../components/Alert';
  import TableSkeleton from '../../components/TableSkeleton';

  export default {
    name: 'ClientsPage',
    components: {
      Header,
      Sidebar,
      Alert,
      TableSkeleton
    },
    data() {
      return {
        user: null,

        clients: [],

        save_loading: false,

        getting_clients: false,
        getting_client_errors: "",

        alert_type: '',
        alert_message: '',
        alert_visible: false,

        client: {
          client_name: ''
        },
      }
    },
    created() {
      this.user = JSON.parse(localStorage.getItem('user'));
      this.getClients();
    },
    methods: {
      getClients() {
        this.getting_clients = true;
        this.getting_client_errors = '';
        axios.get('api/clients')
          .then(response => {
            if(response.data.status) {
              this.clients = response.data.message;
            }
            else {
              this.alertshow('danger',response.data.message);
              this.getting_client_errors = response.data.message;
            }
            this.getting_clients = false;
          })
          .catch(error => {
            let error_message = error.response.data.message;
            this.getting_client_errors = error.response.data.message;
            for (const property in error.response.data.errors) {
              error_message += ". "+error.response.data.errors[property];
            }
            this.alertshow('danger',error_message);
            this.getting_clients = false;
          });
      },
      alertdismiss() {
        this.alert_visible = false;
        this.alert_type = '';
        this.alert_message = '';
      },
      alertshow(type,message) {
        this.alert_visible = true;
        this.alert_type = type;
        this.alert_message = message;
      }
    }
  };
</script>

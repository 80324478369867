<template>
  <div class="navbar-custom">
      <Alert :type="this.alert_type" :message="this.alert_message" :visible="this.alert_visible" @dismiss="alertdismiss" />
      <ul class="list-unstyled topnav-menu float-end mb-0">
          <li class="dropdown notification-list topbar-dropdown">
              <a class="nav-link dropdown-toggle nav-user me-0 waves-effect waves-light" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
                  <img src="../../public/assets/images/users/user-1.jpg" alt="user-image" class="rounded-circle">
                  <span class="pro-user-name ms-1">
                      {{user.names}} <i class="mdi mdi-chevron-down"></i> 
                  </span>
              </a>
              <div class="dropdown-menu dropdown-menu-end profile-dropdown ">
                  <!-- item-->
                  <div class="dropdown-header noti-title">
                      <h6 class="text-overflow m-0">Welcome {{user.names}}!</h6>
                  </div>

                  <!-- item-->
                  <router-link to="/settings" class="dropdown-item notify-item">
                      <i class="fe-user"></i>
                      <span>My Account</span>
                  </router-link>

                  <!-- item-->
                  <router-link to="/settings" class="dropdown-item notify-item">
                      <i class="fe-settings"></i>
                      <span>Settings</span>
                  </router-link>

                  <div class="dropdown-divider"></div>

                  <!-- item-->
                  <a href="javascript:;" @click="logout" class="dropdown-item notify-item">
                      <i class="fe-power-off"></i>
                      <span>Logout</span>
                  </a>

              </div>
          </li>
      </ul>

      <!-- LOGO -->
      <div class="logo-box">
          <router-link to="/dashboard" class="logo logo-dark text-center">
              <span class="logo-sm">
                  <img src="../../public/assets/images/logo-dark.png" alt="">
              </span>
              <span class="logo-lg">
                  <img src="../../public/assets/images/logo-dark.png" alt="">
              </span>
          </router-link>
      </div>

      <ul class="list-unstyled topnav-menu topnav-menu-left mb-0">
          <li>
              <button class="button-menu-mobile">
                  <i class="fe-menu"></i>
              </button>
          </li>

          <li>
              <h4 class="page-title-main">{{title}}</h4>
          </li>

      </ul>

      <div class="clearfix"></div> 
     
  </div>
</template>

<script>
  import axios from '../helpers/axios';
  import Alert from './Alert';
  export default {
    name: 'HeaderComponent',
    components: {
      Alert
    },
    props: ['title'],
    data() {
      return {
        user: null,
        alert_type: '',
        alert_message: '',
        alert_visible: false
      };
    },
    created(){
      this.user = JSON.parse(localStorage.getItem('user'));
    },
    methods: {
      logout() {
        this.loggingout = true;
        axios.get('api/logout')
        .then((result) => {
          if (result.data.status) {
            localStorage.removeItem("user");
            localStorage.removeItem("token");
            this.loggingout = false;
            this.alert_type = 'success';
            this.alert_message = 'Logged out successfully';
            this.alert_visible = true;
            setTimeout(() => {
              this.alert_type = '';
              this.alert_message = '';
              this.alert_visible = false;
              window.location.href = "/login";
            }, 2000);
          }
          else {
            this.loggingout = false;
            this.alert_type = 'danger';
            this.alert_message = result.data.message;
            this.alert_visible = true;
          }
        })
        .catch((error) => {
          this.loggingout = false;
          this.alert_type = 'danger';
          this.alert_message = error.response.data.message;
          for (const property in error.response.data.errors) {
             this.alert_message += ". "+error.response.data.errors[property];
          } 
          this.alert_visible = true;
        })
      },
      alertdismiss() {
        this.alert_visible = false;
        this.alert_type = '';
        this.alert_message = '';
      }
    },
  };
</script>
<style>
  .logo-sm > img{
    width: 4em;
    height: auto;
  }
  .logo-lg > img {
    width: 6em;
    height: auto;
  }
</style>

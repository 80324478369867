<template>
  
    <div id="page">
    
    <WebsiteHeader />
    <!-- /header -->

    <main>
        <div class="top_banner">
            <div class="opacity-mask d-flex align-items-center" data-opacity-mask="rgba(0, 0, 0, 0.3)">
                <div class="container">
                    <div class="breadcrumbs">
                        <ul>
                            <li><a href="#">Home</a></li>
                            <li><a href="#">Category</a></li>
                            <li>Mountain Bike</li>
                        </ul>
                    </div>
                    <h1>Bicycles - Grid listing</h1>
                </div>
            </div>
            <img src="/assets/website/img/slides/slide1.jpg" class="img-fluid" alt="">
        </div>
        <!-- /top_banner -->
            <div id="stick_here"></div>     
            <div class="toolbox elemento_stick">
                <div class="container">
                <ul class="clearfix">
                    <li>
                        <div class="sort_select">
                            <select name="sort" id="sort">
                                <option value="popularity" selected="selected">Sort by popularity</option>
                                <option value="rating">Sort by average rating</option>
                                <option value="date">Sort by newness</option>
                                <option value="price">Sort by price: low to high</option>
                                <option value="price-desc">Sort by price: high to </option>
                            </select>
                        </div>
                    </li>
                    <li>
                        <a href="#0"><i class="ti-view-grid"></i></a>
                        <a href="#"><i class="ti-view-list"></i></a>
                    </li>
                    <li>
                        <a href="#0" class="open_filters">
                            <i class="ti-filter"></i><span>Filters</span>
                        </a>
                    </li>
                </ul>
                </div>
            </div>
            <!-- /toolbox -->
            
            <div class="container margin_30">
            
            <div class="row">
                <aside class="col-lg-3" id="sidebar_fixed">
                    <div class="filter_col">
                        <div class="inner_bt"><a href="#" class="open_filters"><i class="ti-close"></i></a></div>
                        <div class="filter_type version_2">
                            <h4><a href="#filter_1" data-toggle="collapse" class="opened">Categories</a></h4>
                            <div class="collapse show" id="filter_1">
                                <ul>
                                    <li>
                                        <label class="container_check">Road Bicycle <small>12</small>
                                            <input type="checkbox">
                                            <span class="checkmark"></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label class="container_check">Hybrid Bicycle <small>24</small>
                                            <input type="checkbox">
                                            <span class="checkmark"></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label class="container_check">Mountain Bike <small>23</small>
                                            <input type="checkbox">
                                            <span class="checkmark"></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label class="container_check">Cyclist Bicycle <small>11</small>
                                            <input type="checkbox">
                                            <span class="checkmark"></span>
                                        </label>
                                    </li>
                                </ul>
                            </div>
                            <!-- /filter_type -->
                        </div>
                        <!-- /filter_type -->
                        <div class="filter_type version_2">
                            <h4><a href="#filter_2" data-toggle="collapse" class="opened">Color</a></h4>
                            <div class="collapse show" id="filter_2">
                                <ul>
                                    <li>
                                        <label class="container_check">Blue <small>06</small>
                                            <input type="checkbox">
                                            <span class="checkmark"></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label class="container_check">Red <small>12</small>
                                            <input type="checkbox">
                                            <span class="checkmark"></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label class="container_check">Orange <small>17</small>
                                            <input type="checkbox">
                                            <span class="checkmark"></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label class="container_check">Black <small>43</small>
                                            <input type="checkbox">
                                            <span class="checkmark"></span>
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <!-- /filter_type -->
                        <!-- <div class="filter_type version_2">
                            <h4><a href="#filter_3" data-toggle="collapse" class="closed">Brands</a></h4>
                            <div class="collapse" id="filter_3">
                                <ul>
                                    <li>
                                        <label class="container_check">Adidas <small>11</small>
                                            <input type="checkbox">
                                            <span class="checkmark"></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label class="container_check">Nike <small>08</small>
                                            <input type="checkbox">
                                            <span class="checkmark"></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label class="container_check">Vans <small>05</small>
                                            <input type="checkbox">
                                            <span class="checkmark"></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label class="container_check">Puma <small>18</small>
                                            <input type="checkbox">
                                            <span class="checkmark"></span>
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        </div> -->
                        <!-- /filter_type -->
                        <div class="filter_type version_2">
                            <h4><a href="#filter_4" data-toggle="collapse" class="closed">Price</a></h4>
                            <div class="collapse" id="filter_4">
                                <ul>
                                    <li>
                                        <label class="container_check">$0 — $50<small>11</small>
                                            <input type="checkbox">
                                            <span class="checkmark"></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label class="container_check">$50 — $100<small>08</small>
                                            <input type="checkbox">
                                            <span class="checkmark"></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label class="container_check">$100 — $150<small>05</small>
                                            <input type="checkbox">
                                            <span class="checkmark"></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label class="container_check">$150 — $200<small>18</small>
                                            <input type="checkbox">
                                            <span class="checkmark"></span>
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <!-- /filter_type -->
                        <div class="buttons">
                            <a href="#0" class="btn_1">Filter</a> <a href="#0" class="btn_1 gray">Reset</a>
                        </div>
                    </div>
                </aside>
                <!-- /col -->
                <div class="col-lg-9">
                    <div v-if="getting_road_trails" class="row small-gutters">
                        <div v-for="i in 6" :key="i" class="col-6 col-md-4">
                          <ProductSkeleton />
                        </div>
                    </div>
                    <div v-else-if="road_trails.length > 0" class="row small-gutters">
                        <div class="col-6 col-md-4" v-for="(road_trail, index) in road_trails" :key="index">
                            <div class="grid_item">
                                <figure>
                                    <a href="https://www.google.com/maps/place/BK+Arena/@-1.9531555,30.1133955,17z/data=!3m1!4b1!4m6!3m5!1s0x19dca7a1ccdef87b:0x4452855a46876a65!8m2!3d-1.9531555!4d30.1155842!16s%2Fg%2F11h4cyww41" target="_blank">
                                        <img class="img-fluid lazy" src="/assets/website/img/products/product_placeholder_square_medium.jpg" data-src="/assets/website/img/roads/road_trail1.PNG" alt="">
                                    </a>
                                    
                                </figure>
                                <a href="https://www.google.com/maps/place/BK+Arena/@-1.9531555,30.1133955,17z/data=!3m1!4b1!4m6!3m5!1s0x19dca7a1ccdef87b:0x4452855a46876a65!8m2!3d-1.9531555!4d30.1155842!16s%2Fg%2F11h4cyww41" target="_blank">
                                    <h3>{{road_trail.trail_title}}</h3>
                                </a>
                                <!-- <div class="price_box">
                                    <span class="new_price">$4.00</span>
                                    <span class="old_price">$8.00</span>
                                </div> -->
                                <ul>
                                    <li><a href="#0" class="tooltip-1" data-toggle="tooltip" data-placement="left" title="Add to favorites"><i class="ti-heart"></i><span>Add to favorites</span></a></li>
                                    <li><a href="#0" class="tooltip-1" data-toggle="tooltip" data-placement="left" title="Add to compare"><i class="ti-control-shuffle"></i><span>Add to compare</span></a></li>
                                    <li><a href="#0" class="tooltip-1" data-toggle="tooltip" data-placement="left" title="Add to cart"><i class="ti-shopping-cart"></i><span>Add to cart</span></a></li>
                                </ul>
                            </div>
                            <!-- /grid_item -->
                        </div>
                    </div>
                    <div v-else class="container margin_90_65 result">
                        <div class="main_title">
                            <p><b>Result Not Found!! </b></p>
                        </div>
                    </div>
                    <!-- /row -->
                    <div class="pagination__wrapper">
                        <ul class="pagination">
                            <li><a href="#0" class="prev" title="previous page">&#10094;</a></li>
                            <li>
                                <a href="#0" class="active">1</a>
                            </li>
                            <li>
                                <a href="#0">2</a>
                            </li>
                            <li>
                                <a href="#0">3</a>
                            </li>
                            <li>
                                <a href="#0">4</a>
                            </li>
                            <li><a href="#0" class="next" title="next page">&#10095;</a></li>
                        </ul>
                    </div>
                </div>
                <!-- /col -->
            </div>
            <!-- /row -->           
                
        </div>
        <!-- /container -->
    </main>
    <!-- /main -->
    
    <WebsiteFooter />
    <!--/footer-->
    </div>
    <!-- page -->
  
    <div id="toTop"></div><!-- Back to top button -->
</template>
<script>
    import axios from '../helpers/axios';
    import WebsiteHeader from '../components/WebsiteHeader';
    import WebsiteFooter from '../components/WebsiteFooter';
    import ProductSkeleton from '../components/ProductSkeleton';
    export default {
    name: 'RoadTrailPage',
    components: {
      WebsiteHeader,
      WebsiteFooter,
      ProductSkeleton
    },
    data() {
      return {
        user: null,

        road_trails: [],

        getting_road_trails: false,
        getting_road_trail_errors: "",

      }
    },
    created() {
      this.getRoadTrails();
    },
    mounted() { 
        // Load specific scripts
        if (!document.querySelector('script[src="/assets/website/js/sticky_sidebar.min.js"]')) {
            const stickySidebarScripts = document.createElement("script");
            stickySidebarScripts.src = "/assets/website/js/sticky_sidebar.min.js";
            document.head.appendChild(stickySidebarScripts);
        }

        if (!document.querySelector('script[src="/assets/website/js/specific_listing.js"]')) {
            const specificListingScripts = document.createElement("script");
            specificListingScripts.src = "/assets/website/js/specific_listing.js";
            document.head.appendChild(specificListingScripts);
        }

        // Load specific css
        if (!document.querySelector('link[href="/assets/website/css/listing.css"]')) {
            const head = document.head;
            const listing = document.createElement('link');

            listing.rel = 'stylesheet';
            listing.href = '/assets/website/css/listing.css';
            head.appendChild(listing);
        }
    },
    methods:{
        getRoadTrails() {
            this.getting_road_trails = true;
            this.getting_road_trail_errors = '';
            axios.get('api/road_trails')
              .then(response => {
                if(response.data.status) {
                  this.road_trails = response.data.message;
                }
                else {
                  this.alertshow('danger',response.data.message);
                  this.getting_road_trail_errors = response.data.message;
                }
                this.getting_road_trails = false;
              })
              .catch(error => {
                let error_message = error.response.data.message;
                this.getting_road_trail_errors = error.response.data.message;
                for (const property in error.response.data.errors) {
                  error_message += ". "+error.response.data.errors[property];
                }
                this.alertshow('danger',error_message);
                this.getting_road_trails = false;
              });
        },
    }
  }
</script>
<style>
    .result{
        background-color: #f7f7f7;
        padding-top: 5%;
        padding-bottom: 1%;
    }
</style>


<template>
    <div id="wrapper">
      <Alert :type="this.alert_type" :message="this.alert_message" :visible="this.alert_visible" @dismiss="alertdismiss" />

      <Header title="Bike Types" />

      <Sidebar />

      <div class="content-page">
        <div class="content">
          <div class="container-fluid">
            <div class="row">
                <div class="col-md-8">
                    <div class="card">
                        <div class="card-body table-responsive">
                            <h4 class="mt-0 header-title">ALL BIKE TYPES</h4>
                            <TableSkeleton v-if="getting_bike_types" />
                            <table v-else id="datatable" class="table table-bordered dt-responsive table-responsive nowrap">
                              <thead>
                                <tr>
                                    <th>No</th>
                                    <th>Bike Type</th>
                                    <th>Added On</th>
                                    <th>Added By</th>
                                    <th>Actions</th>
                                </tr>
                              </thead>
                              <tbody v-if="bike_types.length>0">
                                <tr v-for="(bike_type, index) in bike_types" :key="bike_type.id">
                                  <td>{{ index + 1 }}</td>
                                  <td>{{ bike_type.type_name }}</td>
                                  <td>{{ bike_type.created_at }}</td>
                                  <td>{{ bike_type.created_by.names }}</td>
                                  <td>
                                    <div class="btn-actions">
                                        <a 
                                          href="javascript:;"
                                        >
                                          <i className="mdi mdi-pencil text-primary mr-1 ml-10"></i>
                                        </a>
                                        <a href="javascript:;"><i className="mdi mdi-delete-forever-outline text-danger mr-1 ml-1"></i></a>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                              <tbody v-else-if="getting_bike_type_errors!=''">
                                <tr>
                                  <td colspan="5"><h5 class="text-center text-danger">{{ getting_bike_type_errors }}</h5></td>
                                </tr>
                              </tbody>
                              <tbody v-else>
                                <tr>
                                  <td colspan="5"><h5 class="text-center text-warning">No type available</h5></td>
                                </tr>
                              </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="card">
                      <div class="card-body table-responsive">
                        <form @submit.prevent="saveBikeType">
                          <div class="form-group">
                            <label for="typr_name" class="form-label">Bike Type</label>
                            <input class="form-control" type="text" v-model="bike_type.type_name" required="" placeholder="Ex: BTX Bike">
                          </div>
                          <div class="mt-3 text-center">
                              <button v-if="save_loading" class="btn btn-primary" type="button">SAVING.....</button>
                              <button v-else class="btn btn-primary" type="submit">ADD BIKE TYPE</button>
                          </div>
                        </form>
                      </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
</template>

<script>
  import axios from '../../helpers/axios';
  import Header from '../../components/Header'; 
  import Sidebar from '../../components/Sidebar';  
  import Alert from '../../components/Alert';
  import TableSkeleton from '../../components/TableSkeleton';

  export default {
    name: 'BikeTypesPage',
    components: {
      Header,
      Sidebar,
      Alert,
      TableSkeleton
    },
    data() {
      return {
        user: null,

        bike_types: [],

        save_loading: false,

        getting_bike_types: false,
        getting_bike_type_errors: "",

        alert_type: '',
        alert_message: '',
        alert_visible: false,

        bike_type: {
          type_name: ''
        },
      }
    },
    created() {
      this.user = JSON.parse(localStorage.getItem('user'));
      this.getBikeTypes();
    },
    methods: {
      saveBikeType() {
        this.save_loading = true;

        axios.post('/api/bike_types', this.bike_type)
          .then(response => {
            if(response.data.status) {
              this.alertshow('success','Bike Type Saved Successfully');
              this.getBikeTypes();
            }
            else {
              this.alertshow('danger',response.data.message);
            }
            this.save_loading = false;
          })
          .catch(error => {
            let error_message = error.response.data.message;
            for (const property in error.response.data.errors) {
              error_message += ". "+error.response.data.errors[property];
            }
            this.alertshow('danger',error_message);
            this.save_loading = false;
          });
      },
      getBikeTypes() {
        this.getting_bike_types = true;
        this.getting_bike_type_errors = '';
        axios.get('api/bike_types')
          .then(response => {
            if(response.data.status) {
              this.bike_types = response.data.message;
            }
            else {
              this.alertshow('danger',response.data.message);
              this.getting_bike_type_errors = response.data.message;
            }
            this.getting_bike_types = false;
          })
          .catch(error => {
            let error_message = error.response.data.message;
            this.getting_bike_type_errors = error.response.data.message;
            for (const property in error.response.data.errors) {
              error_message += ". "+error.response.data.errors[property];
            }
            this.alertshow('danger',error_message);
            this.getting_bike_types = false;
          });
      },
      alertdismiss() {
        this.alert_visible = false;
        this.alert_type = '';
        this.alert_message = '';
      },
      alertshow(type,message) {
        this.alert_visible = true;
        this.alert_type = type;
        this.alert_message = message;
      }
    }
  };
</script>

<template>
  
  <div id="page">
    
  <WebsiteHeader />

  <main class="bg_gray">
    <div class="container margin_60">
      <div class="main_title">
        <h2>Contact The Ride</h2>
        <p>Get out and explore more with our top-rated bicycles – perfect for any adventure.</p>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-4">
          <div class="box_contacts">
            <i class="ti-support"></i>
            <h2>The Ride Help Center</h2>
            <a href="#0">+250-787-831-244</a> - <a href="#0">help@theride.com</a>
            <small>MON to FRI 9am-6pm SAT 9am-2pm</small>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="box_contacts">
            <i class="ti-map-alt"></i>
            <h2>The Ride Showroom</h2>
            <div>Sonatubes, KIGALI - KK 102 st</div>
            <small>MON to FRI 9am-6pm SAT 9am-2pm</small>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="box_contacts">
            <i class="ti-package"></i>
            <h2>The Ride Orders</h2>
            <a href="#0">+250-787-831-244</a> - <a href="#0">order@theride.com</a>
            <small>MON to FRI 9am-6pm SAT 9am-2pm</small>
          </div>
        </div>
      </div>
      <!-- /row -->       
    </div>
    <!-- /container -->
    <div class="bg_white">
      <div class="container margin_60_35">
        <h4 class="pb-3">Drop Us a Line</h4>
        <div class="row">
          <div class="col-lg-4 col-md-6 add_bottom_25">
            <div class="form-group">
              <input class="form-control" type="text" placeholder="Name *">
            </div>
            <div class="form-group">
              <input class="form-control" type="email" placeholder="Email *">
            </div>
            <div class="form-group">
              <textarea class="form-control" style="height: 150px;" placeholder="Message *"></textarea>
            </div>
            <div class="form-group">
              <input class="btn_1 full-width" type="submit" value="Submit">
            </div>
          </div>
          <div class="col-lg-8 col-md-6 add_bottom_25">
          <iframe class="map_contact" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39714.47749917409!2d-0.13662037019554393!3d51.52871971170425!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8a00baf21de75%3A0x52963a5addd52a99!2sLondra%2C+Regno+Unito!5e0!3m2!1sit!2ses!4v1557824540343!5m2!1sit!2ses" style="border: 0" allowfullscreen></iframe>
          </div>
        </div>
        <!-- /row -->
      </div>
      <!-- /container -->
    </div>
    <!-- /bg_white -->
  </main>
  <!--/main-->
    
  <WebsiteFooter />
  <!--/footer-->
  </div>
  <!-- page -->
  
  <div id="toTop"></div><!-- Back to top button -->
</template>
<script>
  import WebsiteHeader from '../components/WebsiteHeader';
  import WebsiteFooter from '../components/WebsiteFooter';
  export default {
    name: 'ConfirmPage',
    components: {
      WebsiteHeader,
      WebsiteFooter
    },
    mounted() { 
      // Load specific css
      if (!document.querySelector('link[href="/assets/website/css/contact.css"]')) {
        const head = document.head;
        const contact = document.createElement('link');

        contact.rel = 'stylesheet';
        contact.href = '/assets/website/css/contact.css';
        head.appendChild(contact);
      }
    }
  }
</script>


import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../views/Home.vue'
import ItemsPage from '../views/Products.vue'
import ProductDetailsPage from '../views/ProductDetails.vue'
import RentPage from '../views/Rent.vue'
import RoadTrailPage from '../views/RoadTrail.vue'
import BikeTypePage from '../views/BikeTypes.vue'
import AboutPage from '../views/About.vue'
import ContactsPage from '../views/Contacts.vue'
import CartPage from '../views/Cart.vue'
import CheckoutPage from '../views/Checkout.vue'
import ConfirmPage from '../views/Confirm.vue'
import AuthPage from '../views/Auth.vue'
// import Error404Page from '../views/Error404.vue'
import LoginPage from '../views/Login.vue'
import DashboardPage from '../views/dashboard/Dashboard.vue'
import OrdersPage from '../views/dashboard/Orders.vue'
import RentsPage from '../views/dashboard/Rents.vue'
import ClientsPage from '../views/dashboard/Clients.vue'
import ProductsPage from '../views/dashboard/Products.vue'
import UnitsPage from '../views/dashboard/Units.vue'
import RoadTrailsPage from '../views/dashboard/RoadTrails.vue'
import BikeTypesPage from '../views/dashboard/BikeTypes.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/products/:category_id',
    name: 'Items',
    component: ItemsPage
  },
  {
    path: '/rent',
    name: 'Rent',
    component: RentPage
  },
  {
    path: '/product-details/:product_slug',
    name: 'ProductDetails',
    component: ProductDetailsPage
  },
  {
    path: '/road-trail',
    name: 'RoadTrail',
    component: RoadTrailPage
  },
  {
    path: '/bike-types',
    name: 'BikeTypePage',
    component: BikeTypePage
  },
  {
    path: '/about',
    name: 'About',
    component: AboutPage
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: ContactsPage
  },
  {
    path: '/cart',
    name: 'Cart',
    component: CartPage
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: CheckoutPage
  },
  {
    path: '/confirm',
    name: 'Confirm',
    component: ConfirmPage
  },
  {
    path: '/auth',
    name: 'Auth',
    component: AuthPage
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardPage,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/dashboard/orders',
    name: 'Orders',
    component: OrdersPage,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/dashboard/rents',
    name: 'Rents',
    component: RentsPage,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/dashboard/clients',
    name: 'Clients',
    component: ClientsPage,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/dashboard/products',
    name: 'Products',
    component: ProductsPage,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/dashboard/units',
    name: 'Units',
    component: UnitsPage,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/dashboard/road-trails',
    name: 'RoadTrails',
    component: RoadTrailsPage,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/dashboard/bike-types',
    name: 'BikeTypes',
    component: BikeTypesPage,
    meta: {
      requiresAuth: true
    }
  },
  // {
  //   path: '*',
  //   name: 'Error404',
  //   component: Error404Page
  // },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // Remove existing custom bootstrap for dashboard
  const existingCustom = document.querySelector('link[href="/assets/website/css/bootstrap.custom.min.css"]');
  if (existingCustom && to.matched.some(record => record.path.includes('dashboard'))) {
    const head = document.getElementsByTagName('head')[0];
    head.removeChild(existingCustom);
  }

  const loggedIn = JSON.parse(localStorage.getItem("user"));
  if (to.matched.some(record => record.meta.requiresAuth) && !loggedIn) {
    next('/login');
  } else {
    next(); // public route, proceed to the route
  }
});

export default router

<template>
  
  <div id="page">
    
  <WebsiteHeader />
  <!-- /header -->
    
  <main class="bg_gray">
    <div class="container margin_30">
      <div class="page_header">
        <div class="breadcrumbs">
          <ul>
            <li><a href="#">Home</a></li>
            <li><a href="#">Category</a></li>
            <li>Cart</li>
          </ul>
        </div>
        <h1>Cart</h1>
      </div>
      <!-- /page_header -->
      <table class="table table-striped cart-list">
        <thead>
          <tr>
            <th>
              Product
            </th>
            <th>
              Price
            </th>
            <th>
              Quantity
            </th>
            <th>
              Subtotal
            </th>
            <th>
              
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div class="thumb_cart">
                <img src="/assets/website/img/products/product_placeholder_square_small.jpg" data-src="/assets/website/img/products/product_1.jpg" class="lazy" alt="Image">
              </div>
              <span class="item_cart">Trek Domane</span>
            </td>
            <td>
              <strong>$140.00</strong>
            </td>
            <td>
              <div class="numbers-row">
                <input type="text" value="1" id="quantity_1" class="qty2" name="quantity_1">
              <div class="inc button_inc">+</div><div class="dec button_inc">-</div></div>
            </td>
            <td>
              <strong>$140.00</strong>
            </td>
            <td class="options">
              <a href="#"><i class="ti-trash"></i></a>
            </td>
          </tr>
          <tr>
            <td>
              <div class="thumb_cart">
                <img src="/assets/website/img/products/product_placeholder_square_small.jpg" data-src="/assets/website/img/products/product_2.jpg" class="lazy" alt="Image">
              </div>
              <span class="item_cart">Specialized Roubaix</span>
            </td>
            <td>
              <strong>$110.00</strong>
            </td>
            <td>
              <div class="numbers-row">
                <input type="text" value="1" id="quantity_2" class="qty2" name="quantity_2">
              <div class="inc button_inc">+</div><div class="dec button_inc">-</div></div>
            </td>
            <td>
              <strong>$110.00</strong>
            </td>
            <td class="options">
              <a href="#"><i class="ti-trash"></i></a>
            </td>
          </tr>
          <tr>
            <td>
              <div class="thumb_cart">
                <img src="/assets/website/img/products/product_placeholder_square_small.jpg" data-src="/assets/website/img/products/product_1.jpg" class="lazy" alt="Image">
              </div>
              <span class="item_cart">Cannondale SuperSix EVO</span>
            </td>
            <td>
              <strong>$90.00</strong>
            </td>
            
            <td>
              <div class="numbers-row">
                <input type="text" value="1" id="quantity_3" class="qty2" name="quantity_3">
              <div class="inc button_inc">+</div><div class="dec button_inc">-</div></div>
            </td>
            <td>
              <strong>$90.00</strong>
            </td>
            <td class="options">
              <a href="#"><i class="ti-trash"></i></a>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="row add_top_30 flex-sm-row-reverse cart_actions">
        <div class="col-sm-4 text-right">
          <button type="button" class="btn_1 gray">Update Cart</button>
        </div>
        <div class="col-sm-8">
          <div class="apply-coupon">
            <div class="form-group form-inline">
              <input type="text" name="coupon-code" value="" placeholder="Promo code" class="form-control"><button type="button" class="btn_1 outline">Apply Coupon</button>
            </div>
          </div>
        </div>
      </div>
      <!-- /cart_actions -->
  
    </div>
    <!-- /container -->
    
    <div class="box_cart">
      <div class="container">
        <div class="row justify-content-end">
          <div class="col-xl-4 col-lg-4 col-md-6">
            <ul>
              <li>
                <span>Subtotal</span> $240.00
              </li>
              <li>
                <span>Shipping</span> $7.00
              </li>
              <li>
                <span>Total</span> $247.00
              </li>
            </ul>
            <router-link to="checkout" class="btn_1 full-width cart">Proceed to Checkout</router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- /box_cart -->
  </main>
  <!--/main-->
    
  <WebsiteFooter />
  <!--/footer-->
  </div>
  <!-- page -->
  
  <div id="toTop"></div><!-- Back to top button -->
</template>
<script>
  import WebsiteHeader from '../components/WebsiteHeader';
  import WebsiteFooter from '../components/WebsiteFooter';
  export default {
    name: 'CartPage',
    components: {
      WebsiteHeader,
      WebsiteFooter
    },
    mounted() { 
      // Load specific css
      if (!document.querySelector('link[href="/assets/website/css/cart.css"]')) {
        const head = document.head;
        const cart = document.createElement('link');

        cart.rel = 'stylesheet';
        cart.href = '/assets/website/css/cart.css';
        head.appendChild(cart);
      }
    }
  }
</script>


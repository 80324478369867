<template>
    <div id="wrapper">
      <Alert :type="this.alert_type" :message="this.alert_message" :visible="this.alert_visible" @dismiss="alertdismiss" />

      <Header title="Products" />

      <Sidebar />

      <div class="content-page">
        <div class="content">
          <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body table-responsive">
                            <h4 class="mt-0 header-title">ALL PRODUCTS 
                            <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#add-product">Add Product</button></h4>
                            <TableSkeleton v-if="getting_products" />
                            <table v-else id="datatable" class="table table-bordered dt-responsive table-responsive nowrap">
                              <thead>
                                <tr>
                                    <th>No</th>
                                    <th>Image</th>
                                    <th>Name</th>
                                    <th>Category</th>
                                    <th>Type</th>
                                    <th>Buy Price</th>
                                    <th>Rent price/day</th>
                                    <th>Unit</th>
                                    <th>Active for buy</th>
                                    <th>Active for rent</th>
                                    <th>Details</th>
                                    <th>Actions</th>
                                </tr>
                              </thead>
                              <tbody v-if="products.length>0">
                                <tr v-for="(product, index) in products" :key="product.id">
                                  <td>{{ index + 1 }}</td>
                                  <td><img :src="product.images[0].image_path" style="height: 3.5em; width: 3.5em; object-fit: contain;" /></td>
                                  <td>{{ product.product_name }}</td>
                                  <td>{{ product.category.category_name }}</td>
                                  <td>{{ product.type.type_name }}</td>
                                  <td>{{ product.buy_price }} RWF</td>
                                  <td>{{ product.rent_price_per_day }} RWF</td>
                                  <td>{{ product.unit.unit_name }}</td>
                                  <td>{{ product.active_for_buy }}</td>
                                  <td>{{ product.active_for_rent }}</td>
                                  <td>{{ product.details }}</td>
                                  <td>
                                    <div class="btn-actions">
                                        <a 
                                          href="javascript:;"
                                        >
                                          <i className="mdi mdi-pencil text-primary mr-1 ml-10"></i>
                                        </a>
                                        <a href="javascript:;"><i className="mdi mdi-delete-forever-outline text-danger mr-1 ml-1"></i></a>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                              <tbody v-else-if="getting_product_errors!=''">
                                <tr>
                                  <td colspan="12"><h5 class="text-center text-danger">{{ getting_product_errors }}</h5></td>
                                </tr>
                              </tbody>
                              <tbody v-else>
                                <tr>
                                  <td colspan="12"><h5 class="text-center text-warning">No product available</h5></td>
                                </tr>
                              </tbody>
                            </table>
                        </div>
                    </div>
                   
                </div>
            </div>
          </div>
        </div>
      </div>
      
      <!-- new product modal content -->
      <div id="add-product" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-md modal-right">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="standard-modalLabel">ADD NEW PRODUCT</h4>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <form @submit.prevent="saveproduct">
                  <div class="row">
                    <div class="col-md-6">
                      <label for="product_name" class="form-label">Product Name</label>
                      <input class="form-control" type="text" v-model="product.product_name" required="" placeholder="Ex: BTX Bike">
                    </div>
                    <div class="col-md-6">
                      <label for="category_id" class="form-label">Product Category</label>
                      <select class="form-control" v-model="product.category_id" @change="categoryChange()">
                        <option value="">Select Category</option>
                        <option v-for="(category,index) in productCategories" :key="`category_${index}`" :value="category.id">{{ category.category_name }}</option>
                      </select>
                    </div>
                    <div class="col-md-6" v-if="product.category_id == 1">
                      <label for="type_id" class="form-label">Bike Type</label>
                      <select class="form-control" v-model="product.type_id">
                        <option value="">Select bike type</option>
                        <option v-for="(type,index) in bikeTypes" :key="`type_${index}`" :value="type.id">{{ type.type_name }}</option>
                      </select>
                    </div>
                    <div class="col-md-6">
                      <label for="active_for_buy" class="form-label">Active for Buy</label>
                      <select class="form-control" v-model="product.active_for_buy">
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                    <div class="col-md-6">
                      <label for="active_for_rent" class="form-label">Active for Rent</label>
                      <select class="form-control" v-model="product.active_for_rent">
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                    <div class="col-md-6">
                      <label for="buy_price" class="form-label">Buy Price</label>
                      <input class="form-control" type="number" v-model="product.buy_price" placeholder="Price for buying" required="">
                    </div>
                    <div class="col-md-6" v-if="product.active_for_rent == 'Yes'">
                      <label for="rent_price_per_day" class="form-label">Rent Price per Day</label>
                      <input class="form-control" type="number" v-model="product.rent_price_per_day" placeholder="Price for renting" required="">
                    </div>
                    <div class="col-md-6">
                      <label for="unit_id" class="form-label">Unit</label>
                      <select class="form-control" v-model="product.unit_id">
                        <option value="">Select selling unit</option>
                        <option v-for="(unit,index) in units" :key="`unit_${index}`" :value="unit.id">{{ unit.unit_name }}</option>
                      </select>
                    </div>
                    <div class="col-md-6">
                      <label for="discount" class="form-label">Discount</label>
                      <input class="form-control" type="number" v-model="product.discount">
                    </div>
                    <div class="col-md-12">
                      <label for="details" class="form-label">Details</label>
                      <textarea class="form-control" v-model="product.details" placeholder="Product details"></textarea>
                    </div>
                    <div class="col-md-12 mt-3">
                      <div class="images-container">
                        <div v-for="(image, index) in product.previewUrls" :key="index" class="image-container">
                          <img :src="image" height="150" alt="Product Image">
                          <button type="button" class="delete-btn" @click="removeImage(index)">
                            <i class="fas fa-trash text-danger"></i>
                          </button>
                          <p class="image-index">{{ index + 1 }}</p>
                        </div>
                      </div>
                      <label class="upload-label" v-bind:class="{ 'upload-label--disabled': product.previewUrls.length >= 5 }">
                        <span class="upload-label-text">+ Add Images</span>
                        <span v-if="product.previewUrls.length > 0">{{ product.previewUrls.length }} image(s) selected</span>
                        <span v-else>up to 5 images</span>
                        <input type="file" class="sr-only" accept="image/*" @change="onFileChange" multiple>
                      </label>
                      <p v-if="product.previewUrls.length === 0" class="error">
                        <span>You can't add a product without an image!</span><br />
                        Please add at least one product image.
                      </p>
                      <p v-if="product.previewUrls.length > 5" class="error">
                        <span>You can't upload more than 5 previewUrls!</span><br />
                        Please delete <b>{{ product.previewUrls.length - 5 }}</b> of them.
                      </p>
                    </div>
                    <div class="col-md-12 mt-3 text-center">
                        <button v-if="save_loading" class="btn btn-primary" type="button">SAVING.....</button>
                        <button v-else class="btn btn-primary" type="submit">ADD PRODUCT</button>
                    </div>
                  </div>
                </form>

            </div>
          </div><!-- /.modal-content -->
        </div><!-- /.modal-dialog -->
      </div><!-- /.modal -->
    </div>
</template>

<script>
  import axios from '../../helpers/axios';
  import Header from '../../components/Header'; 
  import Sidebar from '../../components/Sidebar';  
  import Alert from '../../components/Alert';
  import TableSkeleton from '../../components/TableSkeleton';

  export default {
    name: 'ProductsPage',
    components: {
      Header,
      Sidebar,
      Alert,
      TableSkeleton
    },
    data() {
      return {
        user: null,

        products: [],
        productCategories: [],
        bikeTypes: [],
        units: [],

        save_loading: false,
        save_status: '',
        save_message: '',

        getting_products: false,
        getting_product_errors: "",

        alert_type: '',
        alert_message: '',
        alert_visible: false,

        product: {
          product_name: '',
          category_id: '',
          type_id: '',
          buy_price: '',
          rent_price_per_day: '',
          unit_id: '',
          discount: 0,
          details: '',
          active_for_buy: 'Yes',
          active_for_rent: 'No',
          previewUrls: [],
          images: []
        },
      }
    },
    methods: {
      categoryChange() {
        if (this.product.category_id == 1) {
          this.product.active_for_rent = 'Yes';
        }
        else {
          this.product.active_for_rent = 'No';
        }
      },
      onFileChange(e) {
        const files = e.target.files
        if (this.product.images.length + files.length > 5) {
          alert("You can't upload more than 5 images!")
          return
        }
        for (let i = 0; i < files.length; i++) {
          const file = files[i]
          const reader = new FileReader()
          reader.onload = e => {
            this.product.previewUrls.push(e.target.result)
          }
          reader.readAsDataURL(file)
          this.product.images.push(file)
        }
      },
      removeImage(index) {
        this.product.images.splice(index, 1)
        this.product.previewUrls.splice(index, 1)
      },
      saveproduct() {
        if (this.product.images.length < 1) {
          this.alertshow('error','Product images is required. At least one main product image.');
          return;
        }

        if (this.product.category_id != 1) {
          this.product.type_id = null;
        }

        let formData = new FormData();
        formData.append('category_id', this.product.category_id);
        formData.append('type_id', this.product.type_id);
        formData.append('product_name', this.product.product_name);
        formData.append('buy_price', this.product.buy_price);
        formData.append('rent_price_per_day', this.product.rent_price_per_day);
        formData.append('unit_id', this.product.unit_id);
        formData.append('discount', this.product.discount);
        formData.append('details', this.product.details);
        formData.append('active_for_buy', this.product.active_for_buy);
        formData.append('active_for_rent', this.product.active_for_rent);
        // Add other form fields
        for (let i = 0; i < this.product.images.length; i++) {
          formData.append('images[]', this.product.images[i]);
        }

        this.save_loading = true;

        axios.post('/api/products', formData)
          .then(response => {
            if(response.data.status) {
              this.alertshow('success','Product Saved Successfully');
              this.getProducts();
            }
            else {
              this.alertshow('danger',response.data.message);
            }
            this.save_loading = false;
          })
          .catch(error => {
            let error_message = error.response.data.message;
            for (const property in error.response.data.errors) {
              error_message += ". "+error.response.data.errors[property];
            }
            this.alertshow('danger',error_message);
            this.save_loading = false;
          });
      },
      getProducts() {
        this.getting_products = true;
        axios.get('api/products')
          .then(response => {
            if(response.data.status) {
              this.products = response.data.message;
            }
            else {
              this.alertshow('danger',response.data.message);
              this.getting_product_errors = response.data.message;
            }
            this.getting_products = false;
          })
          .catch(error => {
            let error_message = error.response.data.message;
            this.getting_product_errors = error.response.data.message;
            for (const property in error.response.data.errors) {
              error_message += ". "+error.response.data.errors[property];
            }
            this.alertshow('danger',error_message);
            this.getting_products = false;
          });
      },
      getProductCategories() {
        axios.get('api/product_categories')
          .then(response => {
            if(response.data.status) {
              this.productCategories = response.data.message;
            }
            else {
              this.alertshow('danger',response.data.message);
            }
          })
          .catch(error => {
            let error_message = error.response.data.message;
            for (const property in error.response.data.errors) {
              error_message += ". "+error.response.data.errors[property];
            }
            this.alertshow('danger',error_message);
          });
      },
      getBikeTypes() {
        axios.get('api/bike_types')
          .then(response => {
            if(response.data.status) {
              this.bikeTypes = response.data.message;
            }
            else {
              this.alertshow('danger',response.data.message);
            }
          })
          .catch(error => {
            let error_message = error.response.data.message;
            for (const property in error.response.data.errors) {
              error_message += ". "+error.response.data.errors[property];
            }
            this.alertshow('danger',error_message);
          });
      },
      getUnits() {
        axios.get('api/units')
          .then(response => {
            if(response.data.status) {
            this.units = response.data.message;
            }
            else {
              this.alertshow('danger',response.data.message);
            }
          })
          .catch(error => {
            let error_message = error.response.data.message;
            for (const property in error.response.data.errors) {
              error_message += ". "+error.response.data.errors[property];
            }
            this.alertshow('danger',error_message);
          });
      },
      alertdismiss() {
        this.alert_visible = false;
        this.alert_type = '';
        this.alert_message = '';
      },
      alertshow(type,message) {
        this.alert_visible = true;
        this.alert_type = type;
        this.alert_message = message;
      }
    },
    created() {
      this.user = JSON.parse(localStorage.getItem('user'));
      this.getProducts();
      this.getProductCategories();
      this.getBikeTypes();
      this.getUnits();
    }
  };
</script>
<style type="text/css">
  .nowrap thead th,
  .nowrap tbody tr {
    white-space: nowrap;
  }
  .upload-label {
    display: inline-block;
    width: 10em;
    height: 10em;
    background-color: #f5f5f5;
    border: 2px dashed #ccc;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
  }

  .upload-label--disabled {
    border-color: #ccc;
    cursor: not-allowed;
  }

  .upload-label span {
    display: block;
    font-size: 14px;
    font-weight: bold;
    color: #555;
  }

  .upload-label-text {
    display: block;
    margin-top: 3.5em;
  }

  .images-container {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
  }

  .image-container {
    position: relative;
    margin-right: 10px;
    margin-bottom: 10px;
    width: 150px;
    height: 150px;
  }

  .image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }

  .delete-btn {
    position: absolute;
    top: -8px;
    right: -8px;
    width: 30px;
    height: 30px;
    background-color: #dc3545;
    border: none;
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
  }

  .delete-btn i {
    margin-top: 3px;
  }

  .image-index {
    position: absolute;
    top: 5px;
    left: 5px;
    background-color: #fff;
    border-radius: 10px;
    padding: 2px 5px;
    font-size: 12px;
    font-weight: bold;
  }

  .error {
    margin-top: 20px;
    font-size: 14px;
    color: #dc3545;
    text-align: center;
  }
</style>

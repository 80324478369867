<template>
  <div class="account-pages my-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6 col-xl-4">
          <div class="text-center">
            <a href="#">
              <img src="assets/images/logo-dark.png" alt="" class="mx-auto login-logo" />
            </a>
            <p class="text-muted mt-2 mb-4">The ride Dashboard</p>
          </div>
          <div class="card">
            <div class="card-body p-4">
              <div class="text-center mb-4">
                <h4 class="text-uppercase mt-0">Sign In</h4>
              </div>
              <form @submit.prevent="login">
                <div class="mb-3">
                  <label for="username" class="form-label">Email / Phone</label>
                  <input v-model="payload.username" class="form-control" type="text" id="username" required="" placeholder="Enter Email or Phone" />
                </div>
                <div class="mb-3">
                  <label for="password" class="form-label">Password</label>
                  <input v-model="payload.password" class="form-control" type="password" required="" id="password" placeholder="Enter Password" />
                </div>
                <div class="mb-3">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="checkbox-signin" />
                    <label class="form-check-label" for="checkbox-signin">Remember me</label>
                  </div>
                </div>
                <p :class="{'text-success':status,'text-danger':!status}">{{message}}</p>
                <div class="mb-3 d-grid text-center">
                  <button v-if="logging" class="btn btn-primary" type="button">Loading...</button>
                  <button v-else class="btn btn-primary" type="submit">Log In</button>
                </div>
              </form>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12 text-center">
              <p><a href="#" class="text-muted ms-1"><i class="fa fa-lock me-1"></i>Forgot your password?</a></p>
              <p class="text-muted">Don't have an account? <a href="#" class="text-dark ms-1"><b>Sign Up</b></a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from '../helpers/axios';
  export default {
    name: 'LoginPage',
    data() {
      return {
        logging: false,
        message: '',
        status: '',
        payload: {
          username: '',
          password: ''
        }
      };
    },
    methods: {
      login() {
        this.message = '';
        this.logging = true;
        this.status = '';
        axios.post('api/login',this.payload)
        .then((result) => {
          if (result.data.status) {
            localStorage.setItem("user", JSON.stringify(result.data.user));
            localStorage.setItem("token", result.data.token);
            this.logging = false;
            this.status = true;
            this.message = 'Logging in...';
            setTimeout(() => {
               window.location.href = "/dashboard";
            }, 2000);
          }
          else {
            this.logging = false;
            this.status = false;
            this.message = result.data.message;
          }
        })
        .catch((error) => {
          this.message = error.response.data.message;
          for (const property in error.response.data.errors) {
             this.message += ". "+error.response.data.errors[property];
          } 
          this.logging = false;
          this.status = false;
        })
      },
    },
  };
</script>
<style>
  .login-logo {
    width: 5em;
    height: auto;
  }
</style>

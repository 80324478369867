import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import $ from 'jquery'
window.jQuery = window.$ = $
// import VueJQuery from 'vue-jquery';
// import '../assets/js/app.min.js'

const app = createApp(App)

// app.use(BootstrapVue3)
// app.use(VueJQuery, $);
app.use(router)

app.mount('#app')

<template>
  <div class="left-side-menu">
    <Alert :type="this.alert_type" :message="this.alert_message" :visible="this.alert_visible" @dismiss="alertdismiss" />
    <div class="h-100" data-simplebar>
      <!-- User box -->
      <div class="text-center">
        <div class="dropdown">
          <router-link to="/dashboard/settings" class="user-name dropdown-toggle h5 mt-2 mb-1 d-block" aria-expanded="false">{{ user.names }}</router-link>
          <div class="dropdown-menu user-pro-dropdown">
            <!-- item-->
            <router-link to="/dashboard/settings" class="dropdown-item notify-item">
              <i class="fe-user me-1"></i>
              <span>My Account</span>
            </router-link>
            <!-- item-->
            <router-link to="/dashboard/settings" class="dropdown-item notify-item">
              <i class="fe-settings me-1"></i>
              <span>Settings</span>
            </router-link>
            <!-- item-->
            <a href="#" class="dropdown-item notify-item" @click="logout">
              <i class="fe-log-out me-1"></i>
              <span>Logout</span>
            </a>
          </div>
        </div>
        <ul class="list-inline">
          <li class="list-inline-item">
            <router-link to="/dashboard/settings" class="text-muted left-user-info">
              <i class="mdi mdi-cog"></i>
            </router-link>
          </li>
          <li class="list-inline-item">
            <a href="javascript:;" @click="logout">
              <i class="mdi mdi-power text-danger"></i>
            </a>
          </li>
        </ul>
      </div>
      <!--- Sidemenu -->
      <div id="sidebar-menu">
        <ul id="side-menu">
          <li class="menu-title">Main</li>
          <li>
            <router-link to="/dashboard">
              <i class="mdi mdi-monitor-dashboard"></i>
              <span> Home </span>
            </router-link>
          </li>
          <li>
            <router-link to="/dashboard/products">
              <i class="mdi mdi-clipboard-list-outline"></i>
              <span> Products </span>
            </router-link>
          </li>
          <li>
            <router-link to="/dashboard/orders">
              <i class="mdi mdi-cart-check"></i>
              <span> Orders </span>
            </router-link>
          </li>
          <li>
            <router-link to="/dashboard/rents">
              <i class="mdi mdi-bike"></i>
              <span> Rents </span>
            </router-link>
          </li>
          <li>
            <router-link to="/dashboard/clients">
              <i class="mdi mdi-account-group-outline"></i>
              <span> Clients </span>
            </router-link>
          </li>
          <li class="menu-title">Settings</li>
          <li>
            <router-link to="/dashboard/road-trails">
              <i class="mdi mdi-google-maps"></i>
              <span> Road Trails </span>
            </router-link>
          </li>
          <li>
            <router-link to="/dashboard/units">
              <i class="mdi mdi-scale"></i>
              <span> Units </span>
            </router-link>
          </li>
          <li>
            <router-link to="/dashboard/bike-types">
              <i class="mdi mdi-bicycle"></i>
              <span> Bike Types </span>
            </router-link>
          </li>
          <li>
            <router-link to="/dashboard/users">
              <i class="mdi mdi-account"></i>
              <span> Users </span>
            </router-link>
          </li>
          <li>
            <router-link to="/dashboard/settings">
              <i class="mdi mdi-cog"></i>
              <span> Settings </span>
            </router-link>
          </li>
          <li>
            <a v-if="loggingout" href="javascript:;">
              <i class="mdi mdi-power"></i>
              <span> Logging out... </span>
            </a>
            <a href="javascript:;" @click="logout">
              <i class="mdi mdi-power"></i>
              <span> Logout </span>
            </a>
          </li>
        </ul>
      </div>
      <!-- End Sidebar -->
      <div class="clearfix"></div>
    </div>
    <!-- Sidebar -left -->
  </div>
</template>

<script>
  import axios from '../helpers/axios';
  import Alert from './Alert';
  export default {
    name: 'SidebarComponent',
    components: {
      Alert
    },
    data() {
      return {
        user: null,
        loggingout: false,
        alert_type: '',
        alert_message: '',
        alert_visible: false
      };
    },
    created(){
      this.user = JSON.parse(localStorage.getItem('user'));
    },
    methods: {
      logout() {
        this.loggingout = true;
        axios.get('api/logout')
        .then((result) => {
          if (result.data.status) {
            localStorage.removeItem("user");
            localStorage.removeItem("token");
            this.loggingout = false;
            this.alert_type = 'success';
            this.alert_message = 'Logged out successfully';
            this.alert_visible = true;
            setTimeout(() => {
              this.alert_type = '';
              this.alert_message = '';
              this.alert_visible = false;
              window.location.href = "/login";
            }, 2000);
          }
          else {
            this.loggingout = false;
            this.alert_type = 'danger';
            this.alert_message = result.data.message;
            this.alert_visible = true;
          }
        })
        .catch((error) => {
          this.loggingout = false;
          this.alert_type = 'danger';
          this.alert_message = error.response.data.message;
          for (const property in error.response.data.errors) {
             this.alert_message += ". "+error.response.data.errors[property];
          } 
          this.alert_visible = true;
        })
      },
      alertdismiss() {
        this.alert_visible = false;
        this.alert_type = '';
        this.alert_message = '';
      }
    },
  };
</script>

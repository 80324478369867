<template>
  <footer class="revealed">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-6">
          <h3 data-target="#collapse_1">Quick Links</h3>
          <div class="collapse dont-collapse-sm links" id="collapse_1">
            <ul>
              <li>
                <router-link to="/products/bikes">Bikes</router-link>
              </li>
              <li>
                <router-link to="/products/spares">Spares</router-link>
              </li>
              <li>
                <router-link to="/products/accessories">Accessories</router-link>
              </li>
              <li>
                <router-link to="/road-trail">Road Trail</router-link>
              </li>
              <li>
                <router-link to="/about">About</router-link>
              </li>
              <li>
                <router-link to="/contacts">Contact</router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <h3 data-target="#collapse_2">Categories</h3>
          <div class="collapse dont-collapse-sm links" id="collapse_2">
            <ul>
              <li><router-link to="javascript:;">Road Bicycle</router-link></li>
              <li><router-link to="javascript:;">Hybrid Bicycle</router-link></li>
              <li><router-link to="javascript:;">Mountain Bike</router-link></li>
              <li><router-link to="javascript:;">Cyclist Bicycle</router-link></li>
            </ul>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
            <h3 data-target="#collapse_3">Contacts</h3>
          <div class="collapse dont-collapse-sm contacts" id="collapse_3">
            <ul>
              <li><i class="ti-home"></i>Sonatubes st 567<br>Kigali - Rwanda</li>
              <li><i class="ti-headphone-alt"></i>+250-787-831-244</li>
              <li><i class="ti-email"></i><a href="javascript:;">info@theride.com</a></li>
            </ul>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
            <h3 data-target="#collapse_4">Keep in touch</h3>
          <div class="collapse dont-collapse-sm" id="collapse_4">
            <div id="newsletter">
                <div class="form-group">
                    <input type="email" name="email_newsletter" id="email_newsletter" class="form-control" placeholder="Your email">
                    <button type="submit" id="submit-newsletter"><i class="ti-angle-double-right"></i></button>
                </div>
            </div>
            <div class="follow_us">
              <h5>Follow Us</h5>
              <ul>
                <li><a href="javascript:;"><img src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" data-src="/assets/website/img/twitter_icon.svg" alt="" class="lazy"></a></li>
                <li><a href="javascript:;"><img src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" data-src="/assets/website/img/facebook_icon.svg" alt="" class="lazy"></a></li>
                <li><a href="javascript:;"><img src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" data-src="/assets/website/img/instagram_icon.svg" alt="" class="lazy"></a></li>
                <li><a href="javascript:;"><img src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" data-src="/assets/website/img/youtube_icon.svg" alt="" class="lazy"></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- /row-->
      <hr>
      <div class="row add_bottom_25">
        <div class="col-lg-6">
          <ul class="footer-selector clearfix">
            <li>
              <div class="styled-select lang-selector">
                <select>
                  <option value="English" selected>English</option>
                  <option value="French">French</option>
                  <option value="Spanish">Spanish</option>
                  <option value="Russian">Russian</option>
                </select>
              </div>
            </li>
            <li>
              <div class="styled-select currency-selector">
                <select>
                  <option value="US Dollars" selected>US Dollars</option>
                  <option value="Euro">Euro</option>
                </select>
              </div>
            </li>
            <li><img src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" data-src="/assets/website/img/cards_all.svg" alt="" width="198" height="30" class="lazy"></li>
          </ul>
        </div>
        <div class="col-lg-6">
          <ul class="additional_links">
            <li><a href="javascript:;">Terms and conditions</a></li>
            <li><a href="javascript:;">Privacy</a></li>
            <li><span>© 2023 TheRide</span></li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
  <!--/footer-->
</template>

<script>
  export default {
    name: 'WebsiteFooter'
  }
</script>
